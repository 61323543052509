import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
// import s from './GameModalContainer.scss';
import { Transition } from '@headlessui/react';

@connect((state) => ({
  modal: state.modal,
  isMobile: state.device.isMobile,
}))
class GameModalContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { fullscreen: false };
  }
  //
  // componentDidMount() {
  //   document.body.classList.add(s.modalOpen);
  // }
  //
  // componentWillUnmount() {
  //   document.body.classList.remove(s.modalOpen);
  // }

  fullscreen() {
    const element = document.getElementById('gameModal');

    // Supports most browsers and their versions.
    const requestMethod =
      element.requestFullScreen ||
      element.webkitRequestFullScreen ||
      element.mozRequestFullScreen ||
      element.msRequestFullScreen;

    if (requestMethod) {
      if (this.state.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
        this.setState({ fullscreen: false });
      } else {
        this.setState({ fullscreen: true });
        requestMethod.call(element);
      }
    } else if (typeof window.ActiveXObject !== 'undefined') {
      // Older IE.
      const wscript = new ActiveXObject('WScript.Shell');
      if (wscript !== null) {
        wscript.SendKeys('{F11}');
      }
    }
  }

  render() {
    return (
      <Transition
        appear
        show={this.props.isActive}
        enter="transition duration-300 ease-out"
        enterFrom="transform translate-y-20 opacity-0"
        enterTo="transform translate-y-0 opacity-100"
        leave="transition duration-300 ease-in"
        leaveFrom="translate-y-0 opacity-100"
        leaveTo="transform translate-y-20 opacity-0"
        className="transform fixed top-0 left-0 h-full w-full lg:h-screen md:w-screen z-[99] transform-gpu"
        unmount={false}
      >
        <div
          className={cx(
            'fixed top-0 right-0 w-[calc(100%-var(--sidebar-width))] h-screen z-20 overflow-auto',
          )}
          id="gameModal"
        >
          {/* {!this.props.isMobile && (
          <div onClick={this.fullscreen.bind(this)} className={s.fullScreen} />
        )}
        {!this.props.isMobile && (
          <div onClick={this.props.closePortal} className={s.closeModal}>
            <div className={cx('closeButton', s.closeButton)} />
          </div>
        )} */}
          {this.props.children}
        </div>
      </Transition>
    );
  }
}

GameModalContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
};

export default GameModalContainer;
