/* eslint-disable import/prefer-default-export */
import {createIntl, createIntlCache, defineMessages} from 'react-intl';
import React from 'react';
import universalCookies from 'universal-cookie';
import {get} from '../core/utils';
import {updateCustomMeta, updateMeta} from '../core/DOMUtils';
import history from '../core/history';
import {cache} from '../core/cache';
import {localeUrl} from '../core/url';
import {closeModal, openModal} from './modal.js';
import {startAuthentication} from './auth.js';
import {error} from './notification';
import {getGames} from './gamebrowser';
import _ from 'lodash';
// eslint-disable-next-line new-cap
const cookies = new universalCookies();

const messages = defineMessages({
  // flashIsNotInstalled: {
  //   id: 'message.flashIsNotInstalled',
  //   defaultMessage:
  // `Flash is not enabled. ${<a href="https://get.adobe.com/flashplayer">Enable flash to play.</a>}`,
  //   description: 'Flash is not enabled',
  // },
  notAllowedCountry: {
    id: 'message.notAllowedCountry',
    defaultMessage:
      'Your account data is incomplete. Click on the link below to update your profile.',
    description: 'Incomplete account message',
  },
  profileIsIncomplete: {
    id: 'message.profileIsIncomplete',
    defaultMessage:
      'Your profile data is incomplete. You will be navigated to account page.',
    description: 'Incomplete profile message',
  },
  emailNotVerified: {
    id: 'message.emailNotVerified',
    defaultMessage:
      'The e-mail address is not verified. Please click the link in the activation e-mail',
    description: 'The e-mail address is not verified.',
  },
  gameRoute: {
    id: 'router.game',
    defaultMessage: 'game',
    message: 'game',
    description: 'The route used for the game page',
  },
  routerHome: {
    id: 'router.home',
    defaultMessage: '/',
    description: 'The Route for the homepage',
  },
  slugLiveLobby: {
    id: 'slug.liveLobby',
    defaultMessage: 'live-casino',
    description: 'The SEO description for the home page',
  },
});


export function getGameBySlug(slug) {
  return (dispatch, getState) =>
    new Promise(async resolve => {
      let gameBySlug = {};
      try {
        gameBySlug = await cache.getItem('gameBySlug');
        // eslint-disable-next-line no-empty
      } catch (e) {}
      if (gameBySlug.hasOwnProperty(slug)) {
        resolve({ games: gameBySlug[slug] });
      } else {
        // eslint-disable-next-line no-shadow
        dispatch(getGames()).then(result => {
          const game = result.games.filter(o => {
            if (o.slug === slug) {
              return true;
            }
          });
          gameBySlug[slug] = game;
          cache.setItem('gameBySlug', game);
          resolve({ games: game });
        });
      }
    });
}

function hasFlash() {
  try {
    // eslint-disable-next-line no-undef
    if (new ActiveXObject('ShockwaveFlash.ShockwaveFlash')) {
      return true;
    }
  } catch (e) {
    if (
      navigator.mimeTypes &&
      navigator.mimeTypes['application/x-shockwave-flash'] !== undefined &&
      navigator.mimeTypes['application/x-shockwave-flash'].enabledPlugin
    ) {
      return true;
    }
  }

  return false;
}

export function openGame(game) {
  // eslint-disable-next-line no-shadow
  return async (dispatch, getState) => {
    const cache = createIntlCache();
    const intl = createIntl(
      { locale: getState().intl.locale, messages: getState().intl.messages },
      cache,
    );
    const address = `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${window.location.port}` : ''
    }`;
    const casinoHome =
      getState().router.activeRoute.name == 'home' ||
      getState().router.activeRoute.name == 'liveLobby'
        ? `${address}${localeUrl(
        getState().router.activeRoute.name,
        getState().intl.locale,
        getState().router.activeRoute.params,
        )}`
        : `${address}${localeUrl(
        'home',
        getState().intl.locale,
        getState().router.activeRoute.params,
        )}`;
    const liveHome = `${address}/${localeUrl(
      'liveLobby',
      getState().intl.locale,
      { lobby: intl.formatMessage(messages.slugLiveLobby) },
    )}`;
    const items = await dispatch(getGameBySlug(game.slug));

    if (
      game &&
      ((getState().user.isAuthenticated &&
        getState().license.type === 'UK' &&
        !items.games[0].anonymousFunMode) ||
        getState().license.type === 'MGA')
    ) {
      if (getState().user.isAuthenticated) {
        // First check if player may open a game
        if (
          getState().user.roles.indexOf('Basic Identity Verification Failed') >
          -1 &&
          getState().user.roles.indexOf('Deny Playing') > -1 &&
          getState().license.type === 'UK'
        ) {
          return dispatch(
            openModal('KYC_MODAL', {
              slug: 'kyc-procedure-basic-identity-failed',
            }),
          );
        }

        // if (getState().user.roles.indexOf('Deny Playing') > -1 && getState().license.type === 'UK') {
        //   return dispatch(openModal('KYC_MODAL',{slug: 'kyc-procedure-deny-playing'}));
        // }
      }

      if (!getState().device.isMobile) {
        dispatch(openModal('GAME_MODAL', { className: 'gameModal' }));
      }
      dispatch(openModal('GAME_MODAL', { className: 'gameModal' }));
      dispatch({
        type: 'GAME_LOADING',
        payload: {
          isLoading: true,
          slug: game.slug,
          tableID: game.tableID,
          name: game.name,
        },
      });
    }

    if (
      game &&
      !getState().user.isAuthenticated &&
      getState().license.type === 'UK'
    ) {
      // && getState().license.type === 'UK'
      if (!items.games[0].anonymousFunMode) {
        return dispatch(
          startAuthentication({
            onSuccess: () => {
              dispatch(openGame(game));
              if (game.slug) {
                updateMeta('description', items.games[0].description);
                updateCustomMeta('og:description', items.games[0].description);
                updateCustomMeta(
                  'og:title',
                  `Play ${items.games[0].name} for big wins.`,
                );
                document.title = `Play ${items.games[0].name} for big wins.`;
              }
            },
            onCancel: () => dispatch(closeModal('GAME_MODAL')),
          }),
        );
      }
    }

  };
}

export function showMaxBetModal(game) {
  return (dispatch, getState) => {
    const hide = cookies.get('HIDE_MAX_BET_MODAL');

    if (
      getState().bonus.activeBonuses.length > 0 &&
      (!hide || JSON.parse(hide) !== true)
    ) {
      dispatch(
        openModal('MAX_BET_MODAL', {
          callBack() {
            dispatch(openGame(game));
          },
        }),
      );
    } else {
      dispatch(openGame(game));
    }
  };
}
var gameIsLoading = false;
export function startGame(game, mode = 'M', tableId = null, vtId = null) {
  return async (dispatch, getState) => {

    dispatch({
      type: 'START_GAME',
      payload: { isActive: false },
    });
    // if (gameIsLoading) {
    //   return;
    // }
    gameIsLoading = true;
    //checkIfFrameworkisLoaded(async () => {
      let lastPlayedGames = {};
      let parsed = {};
      try {
        lastPlayedGames = await cache.getItem('LAST_PLAYED_GAMES');
        parsed = JSON.parse(lastPlayedGames);
      } catch (e) {
        //
      }

      const mobileGameIds = _.get(parsed, 'M', []);
      const desktopGameIds = _.get(parsed, 'D', []);
      if (getState().device.isMobile) {
        mobileGameIds.unshift(parseInt(game.id, 10));
      } else {
        desktopGameIds.unshift(parseInt(game.id, 10));
      }

      cache.setItem(
        'LAST_PLAYED_GAMES',
        JSON.stringify({
          D: _.uniq(desktopGameIds)
            .filter(obj => obj)
            .slice(0, 20),
          M: _.uniq(mobileGameIds)
            .filter(obj => obj)
            .slice(0, 20),
        }),
      );

      if (getState().auth.isAuthenticated || getState().license.type === 'UK') {
        mode = 'M';
      }


      //https://livecasino.gamesrv1.com/entry?params=QVVUSF9UT0tFTj1mNWFmYjUxMjdhMzFlNzFhZmU4YTFlZmMxZmQ2MDBkMTE4Y2I0YmJmCmdhbWU9cm91bGV0dGUKc2l0ZT1BCnRhYmxlX2lkPU1PV0RyZWFtMDAwMDAwMDEKaG9tZVVSTD1odHRwczovL3d3dy5wbGF5ZnJhbmsuY29tCmdhbWVJbnRlcmZhY2U9dmlldzE%3D&JSESSIONID=f5afb5127a31e71afe8a1efc1fd600d118cb4bbf&embedded=true&baseUrl=https%3A%2F%2Fwww.playfrank.com&homeURL=https%3A%2F%2Fwww.playfrank.com
      //return '';
    console.log('loadgame');
      return window.AsgFW.loadGame(game.id || 602, mode);
    //});
  };
}

export function showNoBonusModal(game) {
  return (dispatch, getState) => {
    const hide = cookies.get('HIDE_NO_BONUS_MODAL');

    if (
      getState().bonus.activeBonuses.length > 0 &&
      (!hide || JSON.parse(hide) !== true)
    ) {
      dispatch(
        openModal('NO_BONUS_MODAL', {
          callBack() {
            dispatch(openGame(game));
          },
        }),
      );
    } else {
      dispatch(openGame(game));
    }
  };
}

export function getGamesByGameCodeFilterByDevice(gameCodes) {
  return (dispatch, getState) => {
    return new Promise(resolve => {
      // eslint-disable-next-line no-shadow
      const game = getState().gamebrowser.games.find(o => {
        if (
          gameCodes.indexOf(o.Id.toString()) > -1 &&
          o.Platform === (getState().device.isMobile ? 'Mobile' : 'Desktop')
        ) {
          return true;
        }
      });
      resolve(game ? [game.name] : []);
    });
  };
}

export function stopGame() {
  return (dispatch, getState) => {
    dispatch({
      type: 'GAME_UNLOAD',
      payload: { isActive: false },
    });
    try {
      if (getState().router.activeRoute.name !== 'game') {
        history.push(
          localeUrl(
            getState().router.activeRoute.name,
            getState().intl.locale,
            {
              ...get(getState(), 'router.activeRoute.params'),
            },
          ),
          { noTransition: true },
        );
      } else {
        history.push(localeUrl('home', getState().intl.locale), {
          noTransition: true,
        });
      }
    } catch (e) {
      console.log("can't stop game");
    }
  };
}
