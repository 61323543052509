import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import slugify from 'slugify';
import isMobile from 'ismobilejs';
import { setGameCategory } from '../../actions/gamebrowser';
import history from '../../core/history';
import Link from '../Link';
import { scrollTo } from '../../core/utils';

const messages = defineMessages({
  aboutUs: {
    id: 'aboutUs',
    defaultMessage: 'About us',
    description: 'About us',
  },
  faq: {
    id: 'faq',
    defaultMessage: 'FAQ',
    description: 'Friendly Answered Questions',
  },
  termsAndConditions: {
    id: 'termsAndContions',
    defaultMessage: 'Terms and Conditions',
    description: 'Terms and conditions',
  },
  privacyPolicy: {
    id: 'privacyPolicy',
    defaultMessage: 'Privacy Policy',
    description: 'Terms text',
  },
  fairPlay: {
    id: 'fairPlay',
    defaultMessage: 'Fair Play',
    description: 'Terms text',
  },
  vipClub: {
    id: 'vipClub',
    defaultMessage: 'Loyalty Club',
    description: 'Terms text',
  },
  security: {
    id: 'security',
    defaultMessage: 'Security',
    description: 'Terms text',
  },
  cookiePolicy: {
    id: 'cookiePolicy',
    defaultMessage: 'Cookie Policy',
    description: 'Terms text',
  },
  bonusTermsAndContions: {
    id: 'bonusterms',
    defaultMessage: 'Bonus terms & Conditions',
    description: 'Terms text',
  },
  responsibleGaming: {
    id: 'responsibleGaming',
    defaultMessage: 'Responsible Gaming',
    description: 'Responsible Gaming',
  },
  affiliate: {
    id: 'affiliate',
    defaultMessage: 'Affiliate',
    description: 'Affiliate',
  },
  liveChat: {
    id: 'liveChat',
    defaultMessage: 'Live chat',
    description: 'Live chat',
  },
  videoslots: {
    id: 'text.videoslots',
    defaultMessage: 'Video slots',
    description: 'The category name for te gambrowser navigator',
  },
  classicslots: {
    id: 'text.classicslots',
    defaultMessage: 'Classic slots',
    description: 'The category name for te gambrowser navigator',
  },
  jackpot: {
    id: 'text.jackpot',
    defaultMessage: 'Jackpot',
    description: 'The category name for te gambrowser navigator',
  },
  table: {
    id: 'text.table',
    defaultMessage: 'Table games',
    description: 'The category name for te gambrowser navigator',
  },
  live: {
    id: 'text.live',
    defaultMessage: 'Game Shows',
    description: 'The category name for te gambrowser navigator',
  },
  lobby: {
    id: 'lobby',
    defaultMessage: 'Lobby',
    description: 'General word',
  },
  slots: {
    id: 'slots',
    defaultMessage: 'Slots',
    description: 'General word',
  },
  mobileCasino: {
    id: 'mobileCasino',
    defaultMessage: 'Mobile casino',
    description: 'General word',
  },
  slugFeatured: {
    id: 'slug.featured',
    defaultMessage: 'featured',
    description: 'The SEO description for the home page',
  },
  slugVideoSlots: {
    id: 'slug.videoslots',
    defaultMessage: 'slots',
    description: 'The SEO description for the home page',
  },
  slugClassicSlots: {
    id: 'slug.classicslots',
    defaultMessage: 'classic-slots',
    description: 'The SEO description for the home page',
  },
  slugJackpotGames: {
    id: 'slug.jackpotGames',
    defaultMessage: 'jackpot-games',
    description: 'The SEO description for the home page',
  },
  slugTableGames: {
    id: 'slug.tableGames',
    defaultMessage: 'table-games',
    description: 'The SEO description for the home page',
  },
  slugAll: {
    id: 'slug.all',
    defaultMessage: 'all',
    description: 'The SEO description for the home page',
  },
  slugLiveLobby: {
    id: 'slug.liveLobby',
    defaultMessage: 'live-lobby',
    description: 'The SEO description for the home page',
  },
  slugLobby: {
    id: 'slug.lobby',
    defaultMessage: 'lobby',
    description: 'The SEO description for the home page',
  },
});

const FooterNavigation = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const IpCountry = useSelector((state) => state.user.IpCountry);

  const CountryCode = useSelector((state) => state.user.CountryCode);

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const categories = useSelector(
    (state) => state.gamebrowser.gameData.categories,
  );

  const selectGameCategory = (event) => {
    const category = event.currentTarget.getAttribute('data-category');
    const subCategory = event.currentTarget.getAttribute('data-subcategory');
    dispatch(setGameCategory(category, subCategory));
    scrollTo(window, 0.8, {
      scrollTo: {
        y: '#gameBrowser',
        autoKill: false,
        offsetY: isMobile.any ? 60 : 0,
      },
    });
  };

  const openChat = () => {
    if (window.AsgFW) {
      window.AsgFW.showChat();
    }
  };

  const goToLink = (e, link) => {
    e.preventDefault();
    history.push(`/${intl.locale}${link}`);
    return false;
  };

  return (
    <div className="grid md:grid-cols-2 gap-5 mb-5 md:mb-10">
      {['en-gb', 'en-ca', 'en-in', 'en-nz', 'en-za'].indexOf(intl.locale) >
        -1 && (
        <div>
          <h3 className="text-base font-bold   text-white uppercase text-opacity-90  text-left ">
            QUICK LINKS
          </h3>
          <ul className="flex flex-row flex-wrap text-sm items-center mt-2 mb-0 ">
            <li>
              <Link
                to="home"
                transition={false}
                onClick={selectGameCategory}
                className="text-white inline-block align-top mr-4 md:mb-2 py-[14px] md:py-[0px] hover:text-opacity-60 transition-all"
                data-category=""
                data-subcategory=""
              >
                Online Casino
              </Link>
            </li>
            <li>
              <a
                className="text-white inline-block align-top mr-4 md:mb-2 py-[14px] md:py-[0px] hover:text-opacity-60 transition-all"
                href={`/${intl.locale}/casino`}
                onClick={(e) => goToLink(e, '/casino')}
              >
                Casino Games
              </a>
            </li>
            <li>
              <a
                className="text-white inline-block align-top mr-4 md:mb-2 py-[14px] md:py-[0px] hover:text-opacity-60 transition-all"
                href={`/${intl.locale}/casino/slots`}
                onClick={(e) => goToLink(e, '/casino/slots')}
              >
                Online slots
              </a>
            </li>
            {/* <li>
              <a
                className={s.link}
                href={`/${intl.locale}/casino/table-games`}
                onClick={(e) => goToLink(e, '/casino/table-games')}
              >
                <FormattedMessage {...messages.table} />
              </a>
            </li> */}
            <li>
              <a
                className="text-white inline-block align-top mr-4 md:mb-2 py-[14px] md:py-[0px] hover:text-opacity-60 transition-all"
                href={`/${intl.locale}/casino/live-casino`}
                onClick={(e) => goToLink(e, '/casino/live-casino')}
              >
                <FormattedMessage {...messages.live} />
              </a>
            </li>
            <li>
              <a
                className="text-white inline-block align-top mr-4 md:mb-2 py-[14px] md:py-[0px] hover:text-opacity-60 transition-all"
                href={`/${intl.locale}/casino/mobile-casino`}
                onClick={(e) => goToLink(e, '/casino/mobile-casino')}
              >
                <FormattedMessage {...messages.mobileCasino} />
              </a>
            </li>
            <li>
              <a
                className="text-white inline-block align-top mr-4 md:mb-2 py-[14px] md:py-[0px] hover:text-opacity-60 transition-all"
                href={`/${intl.locale}/casino/video-poker`}
                onClick={(e) => goToLink(e, '/casino/video-poker')}
              >
                Video poker
              </a>
            </li>
            <li>
              <a
                className="text-white inline-block align-top mr-4 md:mb-2 py-[14px] md:py-[0px] hover:text-opacity-60 transition-all"
                href={`/${intl.locale}/casino/real-money`}
                onClick={(e) => goToLink(e, '/casino/real-money')}
              >
                Real Money Games
              </a>
            </li>
          </ul>
        </div>
      )}

      {['en-gb', 'en-ca', 'en-in', 'en-nz', 'en-za', 'de'].indexOf(
        intl.locale,
      ) === -1 && (
        <div>
          <h3 className="text-base font-bold   text-white uppercase text-opacity-90 text-left ">
            QUICK LINKS
          </h3>
          <ul className="flex flex-row flex-wrap  text-sm items-center mt-2 mb-0 ">
            <li>
              <Link
                to="home"
                className="text-white inline-block align-top mr-4 md:mb-2 py-[14px] md:py-[0px] hover:text-opacity-60 transition-all"
                transition={false}
                onClick={selectGameCategory}
                className="text-white inline-block align-top mr-4 md:mb-2 py-[14px] md:py-[0px] hover:text-opacity-60 transition-all"
                data-category=""
                data-subcategory=""
              >
                Home
              </Link>
            </li>
            {categories.mainCategories.map(
              (item, i) =>
                i === 0 &&
                item.subCategories.map((subCategory) => (
                  <li>
                    <Link
                      to="lobby"
                      transition={false}
                      onClick={selectGameCategory}
                      params={{
                        category: slugify(subCategory.name, {
                          replacement: '-',
                          lower: true,
                        }),
                        lobby: 'lobby',
                      }}
                      className="text-white inline-block align-top mr-4 md:mb-2 py-[14px] md:py-[0px] hover:text-opacity-60 transition-all"
                      data-category={slugify(item.name, {
                        replacement: '-',
                        lower: true,
                      })}
                      data-subcategory={slugify(subCategory.name, {
                        replacement: '-',
                        lower: true,
                      })}
                    >
                      {subCategory.name}
                    </Link>
                  </li>
                )),
            )}
          </ul>
        </div>
      )}
      {/*
      <ul className={s.navigationRow}>
        <li>
          <strong>Player Support</strong>
        </li>
        <li>
          <Link className={s.link} to="faq">
            <FormattedMessage {...messages.faq} />
          </Link>
        </li>
        <li>
          <Link className={s.link} to="responsibleGaming">
            <FormattedMessage {...messages.responsibleGaming} />
          </Link>
        </li>
        <li>
          <div onClick={openChat} style={{ cursor: 'pointer' }}>
            <FormattedMessage {...messages.liveChat} />
          </div>
        </li>
        <li>
          <Link className={s.link} to="payments">
            Payments
          </Link>
        </li>
      </ul> */}
      <div>
        <h3 className="text-base font-bold   text-white uppercase text-opacity-90   text-left ">
          <strong>More info</strong>
        </h3>
        <ul className="flex flex-row flex-wrap text-sm items-center mt-2 mb-0 ">
          {/* <li>
            <Link className="text-white inline-block align-top mr-4 md:mb-2 py-[14px] md:py-[0px] hover:text-opacity-60 transition-all" to="about">
              <FormattedMessage {...messages.aboutUs} />
            </Link>
          </li> */}

          <li>
            <Link
              className="text-white inline-block align-top mr-4 md:mb-2 py-[14px] md:py-[0px] hover:text-opacity-60 transition-all"
              to="termsAndConditions"
            >
              <FormattedMessage {...messages.termsAndConditions} />
            </Link>
          </li>
          {IpCountry !== 'DE' && !isAuthenticated && (
            <li>
              <Link
                className="text-white inline-block align-top mr-4 md:mb-2 py-[14px] md:py-[0px] hover:text-opacity-60 transition-all"
                to="bonusTerms"
              >
                <FormattedMessage {...messages.bonusTermsAndContions} />
              </Link>
            </li>
          )}
          <li>
            <Link
              className="text-white inline-block align-top mr-4 md:mb-2 py-[14px] md:py-[0px] hover:text-opacity-60 transition-all"
              to="privacyPolicy"
            >
              <FormattedMessage {...messages.privacyPolicy} />
            </Link>
          </li>
          <li>
            <Link
              className="text-white inline-block align-top mr-4 md:mb-2 py-[14px] md:py-[0px] hover:text-opacity-60 transition-all"
              to="responsibleGaming"
            >
              <FormattedMessage {...messages.responsibleGaming} />
            </Link>
          </li>
          <li>
            <Link
              className="text-white inline-block align-top mr-4 md:mb-2 py-[14px] md:py-[0px] hover:text-opacity-60 transition-all"
              to="faq"
            >
              <FormattedMessage {...messages.faq} />
            </Link>
          </li>
          <li>
            <Link
              className="text-white inline-block align-top mr-4 md:mb-2 py-[14px] md:py-[0px] hover:text-opacity-60 transition-all"
              to="fairPlay"
            >
              <FormattedMessage {...messages.fairPlay} />
            </Link>
          </li>
          <li>
            <Link
              className="text-white inline-block align-top mr-4 md:mb-2 py-[14px] md:py-[0px] hover:text-opacity-60 transition-all"
              to="security"
            >
              <FormattedMessage {...messages.security} />
            </Link>
          </li>
          <li>
            <Link
              className="text-white inline-block align-top mr-4 md:mb-2 py-[14px] md:py-[0px] hover:text-opacity-60 transition-all"
              to="payments"
            >
              payments
            </Link>
          </li>
          <li>
            <Link
              className="text-white inline-block align-top mr-4 md:mb-2 py-[14px] md:py-[0px] hover:text-opacity-60 transition-all"
              to="cookiePolicy"
              targetHash="Section16"
            >
              <FormattedMessage {...messages.cookiePolicy} />
            </Link>
          </li>

          {IpCountry !== 'DE' &&
            CountryCode !== 'DE' &&
            IpCountry !== 'UK' &&
            CountryCode !== 'UK' &&
            ['en-gb', 'de'].indexOf(intl.locale) === -1 && (
              <li>
                <Link
                  className="text-white inline-block align-top mr-4 md:mb-2 py-[14px] md:py-[0px] hover:text-opacity-60 transition-all"
                  to="vipClub"
                >
                  <FormattedMessage {...messages.vipClub} />
                </Link>
              </li>
            )}
          <li>
            <a
              className="text-white inline-block align-top mr-4 md:mb-2 py-[14px] md:py-[0px] hover:text-opacity-60 transition-all"
              href="https://frankaffiliates.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage {...messages.affiliate} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default FooterNavigation;
