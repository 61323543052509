import cx from 'classnames';
import React from 'react';
import Link from '../Link';
import s from './BottomBarMenuItem.scss';

const BottomBarMenuItem = props => {
  const {
    counter,
    to,
    params,
    onClick,
    targetHash,
    className,
    active,
    icon,
    label,
  } = props;
  return (
    <Link
      to={to}
      params={params}
      onClick={onClick}
      targetHash={targetHash}
      className={cx(
        'flex flex-col text-white font-medium justify-center items-center',
        className,
        {
          '': active,
        },
      )}
    >
      <img src={icon} width="25" height="25" alt="" className="0" />
      {counter > 0 && <span className={s.badge}>{counter}</span>}
      <span className="text-xs w-full px-1 mt-0.5 truncate leading-none text-center">
        {label}
      </span>
    </Link>
  );
};

export default BottomBarMenuItem;
