import React from 'react';
import { boundMethod } from 'autobind-decorator';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import cx from 'classnames';
import { trackAction } from '../../actions/analytics';
import { startGame } from '../../actions/game';
import { closeModal, openModal } from '../../actions/modal.js';
import { startAuthentication } from '../../actions/auth.js';
import Link from '../Link';
// eslint-disable-next-line import/no-unresolved
import playButton from './play-button.svg?external';
import slugify from 'slugify';

const messages = defineMessages({
  tryForFun: {
    id: 'tryForFun',
    defaultMessage: 'Try for fun',
    description: 'Term on the game tile',
  },
});

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  hasErrored: state.itemsHasErrored,
  isLoading: state.itemsIsLoading,
  isMobile: state.device.isMobile,
  IpCountry: state.user.IpCountry,
  lobbyType: state.router.activeRoute.params.lobbyType,
  hasDemoGames: state.license.hasDemoGames,
});

const mapDispatchToProps = (dispatch) => ({
  startLogin: (game, mode) => {
    dispatch(
      startAuthentication({
        onSuccess: () => {
          dispatch(startGame(game, mode));
        },
      }),
    );
  },
  launchGame: (game, mode, tableId, vtId) => {
    dispatch(closeModal('GAME_SEARCH_MODAL'));
    dispatch(startGame(game, mode, tableId, vtId));

    dispatch(
      trackAction('Start game', {
        variation: 'Default',
        component: 'GameItem',
        id: game.id,
        mode,
      }),
    );
  },
  openGameModeModal: (game, launchGame) => {
    dispatch(openModal('GAME_MODE_MODAL', { game, launchGame }));
  },
});
@connect(mapStateToProps, mapDispatchToProps)
@injectIntl
class GameItem extends React.Component {
  static defaultProps = {
    enabled: true,
    tableId: null,
    vtId: null,
  };

  @boundMethod
  playReal() {
    console.log('launchGame', this.props);
    this.props.launchGame(
      this.props.game,
      'M',
      this.props.tableId,
      this.props.vtId,
    );
  }

  @boundMethod
  playFun() {
    this.props.launchGame(
      this.props.game,
      'D',
      this.props.tableId,
      this.props.vtId,
    );
  }

  @boundMethod
  openInMobile() {
    const {
      isMobile,
      openGameModeModal,
      game,
      launchGame,
      isAuthenticated,
      tableId,
      vtId,
      intl: { locale },
    } = this.props;

    // if (game?.playModes?.includes?.('Demo')) {
    //   this.playFun();
    // } else {
    this.playReal();
    // }
  }

  render() {
    const {
      game,
      className,
      children,
      enabled,
      isMobile,
      isAuthenticated,
      startLogin,
      IpCountry,
      lobbyType,
      tableId,
      vtId,
      intl,
      showLabel,
      index,
      fullwidth,
      portrait = true,
      containerClassName,
    } = this.props;

    function getMappedName(name) {
      if (name === 'PlayNGo') {
        return "Play'n GO";
      }
      return name;
    }
    return enabled ? (
      <div
        className={cx(
          'hover:scale-110 duration-300 transform transition-all group game-item ',
          {
            'lg:block inline-block align-top lg:w-full sm:w-40 w-36 lg:mr-0 mr-2 lg:mb-0 mb-1':
              !fullwidth,
          },
          containerClassName,
        )}
        // onMouseOver={this.checkPage}
        // style={{ animationDelay: `${index < 30 ? index * 0.05 : 0.1}s` }}
      >
        <div
          className={cx(className, portrait ? 's.portrait ' : '', {
            ['animate-fadeInUp opacity-0']: !process.env.BROWSER,
          })}
          // onClick={this.openInMobile}
          onClick={isMobile ? this.openInMobile : null}
          data-id={game.id || '601'}
          data-to="game"
        >
          {!isMobile && !tableId && (
            <div className="bg-darkblue/60 opacity-0 group-hover:opacity-100 scale-90 group-hover:scale-100 transform transition-all duration-300 absolute top-0 left-0 w-full h-full z-20 cursor-pointer flex items-center justify-center">
              <Link
                to={'game'}
                params={{
                  slug: game.id || '601',
                  ...(game?.name && {
                    name: slugify(game?.name, {
                      replacement: '-',
                      lower: true,
                    }),
                  }),
                  tableId,
                  vtId,
                }}
                transition={false}
                // to={
                //   isAuthenticated || !this.state.hasSeoPage ? null : 'gameSeo'
                // }
                // params={
                //   isAuthenticated || !this.state.hasSeoPage
                //     ? {}
                //     : {
                //         slug: game.id,
                //         name: slugifyURL(game.name),
                //       }
                // }
                className="transition-all duration-300 transform hover:scale-110"
                onClick={this.playFun}
                data-id={game.id}
                data-to="game"
                ariaLabel={`Start playing ${game.name}`}
              >
                <img
                  src={playButton}
                  alt=""
                  className="w-16 h-16"
                  height={70}
                  width={70}
                />
              </Link>
              {/* {game.playModes && */}
              {/*  game?.playModes?.includes?.('Demo') && */}
              {/*  !isAuthenticated && */}
              {/*  IpCountry.toLowerCase() !== 'uk' && */}
              {/*  IpCountry.toLowerCase() !== 'de' && ( */}
              {/*    // <Link */}
              {/*    //   // to="game" */}
              {/*    //   // params={{ */}
              {/*    //   //   slug: game.id || '602', */}
              {/*    //   //   tableId, */}
              {/*    //   //   vtId, */}
              {/*    //   // }} */}
              {/*    //   // transition={false} */}
              {/*    //   onClick={this.playFun} */}
              {/*    //   className={cx(s['for-fun-link'], 'for-fun-link')} */}
              {/*    // > */}
              {/*    //   {intl.formatMessage(messages.tryForFun)} */}
              {/*    // </Link> */}
              {/*    <Link */}
              {/*      to="gameSeo" */}
              {/*      params={{ */}
              {/*        slug: game.id, */}
              {/*        name: slugifyURL(game.name), */}
              {/*      }} */}
              {/*      // transition={false} */}
              {/*      // onClick={this.playFun} */}
              {/*      className={cx(s['for-fun-link'], 'for-fun-link')} */}
              {/*    > */}
              {/*      {intl.formatMessage(messages.tryForFun)} */}
              {/*    </Link> */}
              {/*  )} */}
            </div>
          )}
          {children}
        </div>

        {game?.name && (
          <p className="text-xs font-bold font-baloo leading-none mt-1.5 whitespace-normal">
            {game?.name}
          </p>
        )}
        {game?.externalGameProvider && (
          <span className="text-[#6f6d6d] leading-none font-baloo font-medium text-[11px] tracking-wider block mt-0.5 ">
            {getMappedName(
              game?.externalGameSubProvider || game?.externalGameProvider,
            )}
          </span>
        )}
      </div>
    ) : (
      <div className={cx(className, 'disabled')}>{children}</div>
    );
  }
}

export default GameItem;
