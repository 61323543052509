import React from 'react';

import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { startAuthentication } from '../../../actions/auth';
import Link from '../../../components/Link';
import history from '../../../core/history';
import { openModal } from '../../../actions/modal';
import s from './SeoCta.scss';

const messages = defineMessages({
  labelLogin: {
    id: 'label.login',
    description: 'Button text for login',
    defaultMessage: 'Log in',
  },
  signup: {
    id: 'signup',
    defaultMessage: 'Signup',
    description: 'General word',
  },
  bonusLine: {
    id: 'seoctaBonusLine',
    defaultMessage:
      'Play with {totalBonus} Bonus + {totalFreespins} Bonus Spins',
    description: 'seoctaBonusLine',
  },
});

@connect(
  (state) => ({
    licenseType: state.license.type,
    affiliate: state.affiliate,
  }),
  (dispatch) => ({
    startAuthentication: () => dispatch(startAuthentication()),
    openTerms: () =>
      dispatch(
        openModal('BONUS_TERMS_AND_CONDITIONS_MODAL', {
          closeOnEsc: true,
          hasClose: true,
          instance: 'WelcomeBonus',
          type: 'general',
        }),
      ),
  }),
)
@injectIntl
@withStyles(s)
class SeoCta extends React.Component {
  constructor(props) {
    super(props);
    this.ImageOrLink = this.ImageOrLink.bind(this);
    this.transform = this.transform.bind(this);
    this.goToLink = this.goToLink.bind(this);
  }

  openChat() {
    // eslint-disable-next-line no-undef
    window.fcWidget.open();
  }

  root(props) {
    return <span>{props.children}</span>;
  }

  goToLink(e, link) {
    e.preventDefault();
    history.push(`/${this.props.intl.locale}${link}`);
    return false;
  }

  ImageOrLink(node) {
    if (node.attribs.href === 'liveChat') {
      return <Link onClick={this.openChat}>{node.children[0].data}</Link>;
    }
    if (node.attribs.href === 'login') {
      return (
        <Link onClick={this.props.startAuthentication}>
          {node.children[0].data}
        </Link>
      );
    }
    if (
      node.attribs.href.indexOf('http') > -1 ||
      node.attribs.href.indexOf('mailto') > -1
    ) {
      return (
        <a
          rel="noopener noreferrer"
          href={node.attribs.href}
          target="_blank"
          alt={node.children[0].data}
        >
          {node.children[0].data}
        </a>
      );
    }
    if (node.attribs.href.indexOf('/') > -1) {
      return (
        <a
          href={`/${this.props.intl.locale}${node.attribs.href}`}
          alt={node.children[0].data}
          onClick={(e) => this.goToLink(e, node.attribs.href)}
        >
          {node.children[0].data}
        </a>
      );
    }

    return (
      <Link to={node.attribs.href} alt={node.children[0].data}>
        {node.children[0].data}
      </Link>
    );
  }

  img(props) {
    // console.log('img', props);
  }

  transform(node) {
    if (node.type === 'tag' && node.name === 'a') {
      return this.ImageOrLink(node);
    }
    if (node.type === 'tag' && node.name === 'img') {
      return (
        <img
          src={`https://cockpit.playfrank.com${node.attribs.src}`}
          height={node.attribs.height}
          width={node.attribs.width}
        />
      );
    }

    return null;
  }

  render() {
    const {
      licenseType,
      intl,
      openTerms,
      affiliate: {
        welcomePackage: { presentation },
      },
    } = this.props;

    return (
      <div className="relative px-3 bg-gradient-to-bl from-[#2E89FF] to-[#FF2FE7] z-20 flex justify-center items-center py-3">
        <div className="flex items-center max-w-4xl w-full justify-between space-x-5">
          <div className="flex items-center sapce-x-5">
            {/* <img src={cta} alt="" className={s.ctaImage} /> */}
            <div className="flex flex-col ">
              <h3 className="md:text-2xl text-xl leading-none font-bold text-white">
                {intl.formatMessage(messages.bonusLine, {
                  totalBonus: presentation.totalBonus,
                  totalFreespins: presentation.totalFreespins,
                })}
              </h3>
              {licenseType === 'uk' && (
                <span className="text-xs text-white text-opacity-70">
                  Wagering bonus spins 40x,{' '}
                  <Link onClick={openTerms} className="text-white">
                    Bonus T&C apply
                  </Link>
                </span>
              )}
            </div>
          </div>
          <Link
            className="btn btn--primary btn--lg"
            to="register"
            data-id="register"
          >
            {intl.formatMessage(messages.signup)}
          </Link>
        </div>
      </div>
    );
  }
}

export default SeoCta;
