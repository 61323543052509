/**
 * Layout Component
 *
 * This component is responsible for the whole page markup from the root. The wireframe for the site.
 *
 * @category   Layout
 * @author     ggr <gerjo.greenwald@gmail.com>
 * @copyright  2017 MM
 * @version    Release: @package_version@
 * @since      Component available since Release 1.0.0
 */

import useStyles from 'isomorphic-style-loader/useStyles';
import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import BottomBar from '../BottomBar';
import Navigation from '../Navigation';
import Footer from '../Footer';
import Modal from '../Modal/Modal';
import SideBar from '../SideBar';
import GameModal from '../GameModal';
import s from './Layout.scss';
import '../global.scss';
import { useQuery } from '@apollo/client';
import GetPromotionsQuery from '../Sitemap/getPromotions.graphql';
import { useIntl } from 'react-intl';
import cx from 'classnames';
const AddToHomeScreen = React.lazy(() => import('../AddToHomeScreen'));
const ConsentBanner = React.lazy(() => import('../ConsentBanner'));
const GameLoader = React.lazy(() => import('../GameLoader'));
const LiveChat = React.lazy(() => import('../LiveChat'));
// Process the styles

const Layout = ({
  children,
  footer = true,
  sidebar = true,
  bottombar = true,
  navigation = true,
  footerNavigation = true,
}) => {
  // shouldComponentUpdate(nextProps, nextState){
  //   const {
  //     isMadmanConnected,
  //     isEverymatrixConnected,
  //     isLoading
  //   } = nextProps;
  //   if (isEverymatrixConnected && isMadmanConnected && (!isLoading)) {
  //     return true;
  //   }
  //   return false;
  //
  // }
  useStyles(s);
  const intl = useIntl();
  const greaterThanSmall = useSelector(
    (state) => state.browser.greaterThan.small,
  );

  const isLoading = useSelector((state) => state.app.isLoading);
  const isGameLoading = useSelector((state) => state.game.isLoading);
  const isPhone = useSelector((state) => state.device?.mobile.any);
  const licenseType = useSelector((state) => state.license.type);
  const IpCountry = useSelector((state) => state.user.IpCountry);

  const { data } = useQuery(GetPromotionsQuery, {
    fetchPolicy: 'cache-first',
    variables: { locale: intl.locale },
  });

  return (
    <>
      <Suspense fallback={<div />}>
        <ConsentBanner key={isLoading ? 'loading' : 'loaded'} />
      </Suspense>
      {isGameLoading && (
        <div className={cx(s.gameLoaderContainer, 'md:hidden')}>
          <Suspense fallback={<div />}>
            <GameLoader />
          </Suspense>
        </div>
      )}
      {sidebar && <SideBar />}
      {navigation && <Navigation />}
      {children}
      {footer && <Footer navigation={footerNavigation} />}
      <BottomBar />
      <Modal />
      {process.env.BROWSER && !isLoading && (
        <Suspense fallback={<div />}>
          <GameModal />
        </Suspense>
      )}
      {process.env.BROWSER && (
        <Suspense fallback={<div />}>
          <LiveChat />
        </Suspense>
      )}
      {process.env.BROWSER && (
        <Suspense fallback={<div />}>
          <AddToHomeScreen />
        </Suspense>
      )}
    </>
  );
};

export default Layout;
/*
{!isSsr && <PageLoader />}
 */
