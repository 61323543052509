import React from 'react';
import { injectIntl } from 'react-intl';
import { find } from '../../core/cockpit';

// import s from './Affiliates.scss';

@injectIntl
class Affiliates extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { affiliates: [] };
  }

  componentDidMount() {
    const {
      intl: { locale },
    } = this.props;

    const langField =
      locale == 'en' ? '' : `_${locale === 'en-gb' ? 'uk' : locale}`;
    const filter = {};
    filter[`publish${langField}`] = 1;
    const { licenseType, intl, activeRouteName } = this.props;
    find('footeraffiliates', filter, 10, 1, intl.locale).then((result) => {
      this.setState({ affiliates: result.entries });
    });
  }

  render() {
    const { intl } = this.props;
    const { affiliates } = this.state;

    if (affiliates?.length === 0) {
      return null;
    }
    return (
      <div>
        <h3 className="text-base font-black uppercase text-white">
          OUR AFFILIATES
        </h3>
        <div className="relative flex items-center space-x-3 whitespace-nowrap overflow-auto">
          {affiliates &&
            affiliates.map((affiliate) => (
              <a
                href={affiliate.url}
                rel="noopener noreferrer"
                target="_blank"
                name={affiliate.alt}
                key={affiliate.url}
                className="w-auto flex items-center h-11  "
              >
                <img
                  src={`https://www.playfrank.com/cdn-cgi/image/height=24,format=auto,dpr=2,sharpen=1/https://cockpit.playfrank.com/${affiliate.logo.path}`}
                  loading="lazy"
                  className="h-6 object-contain"
                  alt={affiliate.alt || 'Visit one of our partners'}
                  height="24"
                  width="100"
                  // height={affiliate.height}
                  // width={affiliate.width}
                  // style={{
                  //   height: `${affiliate.height}px`,
                  //   width: `${affiliate.width}px`,
                  // }}
                />
              </a>
            ))}
        </div>
      </div>
    );
  }
}

export default Affiliates;
