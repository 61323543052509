import React, {lazy} from 'react';
import {useSelector} from 'react-redux';
import SsrCompatibleSuspense from '../../SsrCompatibleSuspense';

const Xperience = lazy(() =>
  import(/* webpackChunkName: 'Xperience' */ './Xperience'),
);

function ActionHolder() {
  const game = useSelector(state => state.game);
  const xperience = useSelector(state => state.xperience);
  const isNavigationOpen = useSelector(
    state => state.navigation.isNavigationOpen,
  );

  return (
    <div className=" ">
      {/* <div>
        <AccountImg />
      </div> */}
      <div>
        <SsrCompatibleSuspense fallback={<div />}>
          <Xperience />
        </SsrCompatibleSuspense>
      </div>
    </div>
  );
}

export default ActionHolder;
