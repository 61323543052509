import React from 'react';
import Link from '../Link';

export const MenuItem = ({
  to,
  params,
  onClick = () => {},
  targetHash,
  className = '',
  counter,
  icon,
  children,
}) => {
  return (
    <Link
      to={to}
      params={params}
      onClick={onClick}
      targetHash={targetHash}
      className={`flex items-center transform lg:translate-x-0 -translate-x-1/2 left-1/2 lg:left-0 lg:w-full w-screen lg:rounded-none rounded-none relative  space-x-2 font-display font-medium lg:px-3 py-3 md:py-2 hover:bg-white/20 transition-all px-[calc(100vw-320px)] ${className}`}
    >
      <img
        src={icon}
        alt=""
        className="w-7 h-8 max-w-[25px] max-h-[21px]"
        width={25}
        height={21}
      />
      <span className="text-base lg:text-sm text-white hover:text-opacity-70 font-display font-medium">
        {children}
        {counter > 0 && (
          <span className="  w-4 h-4 text-xs text-center rounded-full inline-block font-bold absolute -top-0 bg-gradient-to-bl from-[#FFE32E] to-[#FF2FE7]">
            {counter}
          </span>
        )}
      </span>
    </Link>
  );
};

export default MenuItem;
