import _ from 'lodash';
import { cockpit, locales } from '../config';
import fetch from './fetch';

export async function getRoute(slug, lang, collection = 'staticpage') {
  return queryPage(collection, slug, lang);
}

export function mapLanguage(lang) {
  switch (lang) {
    case 'en-ca':
      return '_ca';
    case 'en-nz':
      return '_nz';
    case 'en-za':
      return '_za';
    case 'en-in':
      return '_in';
    case 'en-gb':
      return '_uk';
    case 'de':
      return '_de';
    case 'no':
      return '_no';
    default:
      return '';
  }
}

export function mapFieldLanguage(lang) {
  switch (lang) {
    case 'en-ca':
      return 'ca';
    case 'en-nz':
      return 'nz';
    case 'en-za':
      return 'za';
    case 'en-in':
      return 'in';
    case 'en-gb':
      return 'uk';
    case 'uk':
      return 'en-gb';
    case 'de':
      return 'de';
    case 'no':
      return 'no';
    default:
      return '';
  }
}

function mapLanguageFields(res, lang = false) {
  const entries = _.get(res, 'entries');

  const langField = mapLanguage(lang);

  const newResult = _.map(entries, (entry, entryKey) => {
    _.map(res.fields, (field, key) => {
      if (field.localize) {
        entry[`${key}_en`] = entry[key];
        entry[key] = entry[lang == 'en' ? key : `${key}${langField}`];
      }
      /*
      // replace so we can process
      if (_.isString(entry[key])) {
        entry[key] = entry[key].replace(
          /\[(.*?)]\((.*?)\)/,
          '<a href="$2">$1</a>'
        );
      }
      */
    });
    return entry;
  });

  res.entries = newResult;

  return res;
  // We reject here because we want just 1 entry back as soon as we found it.
}

export function find(
  collection,
  filter = {},
  limit = 1,
  populate = 0,
  lang = false,
  skip = 0,
  sort = { _created: -1 },
  cacheEnable = true,
) {
  return new Promise((resolve, reject) => {
    console.log(
      `${cockpit.url}/api/collections/get/${collection}?token=${cockpit.token}`,
    );
    fetch(
      `${cockpit.url}/api/collections/get/${collection}?token=${cockpit.token}`,
      {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          filter,
          limit,
          skip,
          sort,
          populate, // resolve linked collection items
        }),
      },
    )
      .then((res) => res.json())
      .then((res) => {
        resolve(lang ? mapLanguageFields(res, lang) : res);
      })
      .catch((e) => {
        console.log(e);
        resolve({});
      });
  });
}

export function getCollections() {
  return new Promise((resolve, reject) => {
    return fetch(
      `${cockpit.url}/api/collections/listCollections?token=${cockpit.token}`,
    ).then((res) => resolve(res.json()));
  });
}

export async function queryPage(collection, slug, lang) {
  const langField = mapLanguage(lang);
  // filter[`permalink_${langField}`] = slug;

  return new Promise((resolve, reject) => {
    return fetch(
      `${cockpit.url}/api/collections/get/${collection}?token=${cockpit.token}`,
      {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          filter: {
            [`permalink${langField}`]: slug,
            [`publish${langField}`]: 1,
          },
          limit: 1,
          populate: 0, // resolve linked collection items
        }),
      },
    )
      .then((res) => res.json())
      .then((res) => {
        const entry = _.get(res, 'entries.0');

        if (entry) {
          const newEntry = { hreflang: {} };
          _.map(res.fields, (field, key) => {
            if (key.indexOf('permalink') > -1) {
              locales.map((locale) => {
                if (entry[`publish${mapLanguage(locale)}`]) {
                  const tmp =
                    entry[`${key}${mapLanguage(locale)}`].substring(1);

                  newEntry.hreflang[locale] =
                    entry[`${key}${mapLanguage(locale)}`].substring(1);
                }
              });
              newEntry[key] =
                entry[
                  lang == 'en' || !field.localize ? key : `${key}${langField}`
                ];
            } else {
              newEntry[key] =
                entry[
                  lang == 'en' || !field.localize ? key : `${key}${langField}`
                ];

              // replace so we can process
              if (_.isString(newEntry[key])) {
                newEntry[key] = newEntry[key].replace(
                  /\[(.*?)]\((.*?)\)/g,
                  '<a href="$2">$1</a>',
                );
              }
            }
          });

          newEntry._by = entry._by;
          newEntry._created = entry._created;
          newEntry._id = entry._id;
          newEntry._mby = entry._mby;
          newEntry._modified = entry._modified;

          // We reject here because we want just 1 entry back as soon as we found it.
          resolve({
            fieldData: res.fields,
            field: newEntry,
            collection,
          });
        } else {
          // else, gracefully resolve
          reject();
        }
      });
  });
}

/*
.then(function(page) {

        });
 */
