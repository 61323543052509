import React from 'react';
import { connect, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import MarkdownReact from 'react-markdown';
import { transform } from '../../core/aspire';
import { useQuery } from '@apollo/client';
import getFooterSeoQuery from './get-footer-seo.graphql';
const Seo = () => {
  const intl = useIntl();
  const selectedCategory = useSelector(
    (state) => state.gamebrowser.selectedCategory,
  );
  const activeRouteName = useSelector((state) => state.router.activeRoute.name);

  const { loading, error, data } = useQuery(getFooterSeoQuery, {
    variables: { activeRouteName, locale: intl.locale },
  });

  if (selectedCategory) {
    return null;
  }

  return (
    <div className="prose prose-headings:text-darkblue font-baloo prose-headings:mb-2 prose-headings:mt-7 prose-lg max-w-6xl mx-auto">
      <div className="">
        <h2 className=" ">{data?.getFooterSeo?.title}</h2>
        <div className="">
          <MarkdownReact
            children={data?.getFooterSeo?.content}
            components={{ replace: transform }}
          />
        </div>
      </div>
    </div>
  );
};

export default Seo;
