// Get the specific headers
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import ModalHeaderAuthUk from '../Auth/AuthHeaderUk.svg'; // done
import ModalHeaderTerms from '../TermsAndConditionsModal/TermsAndConditionsHeader.svg'; // done needs tlc
import ModalHeaderTermsUk from '../TermsAndConditionsModal/TermsAndConditionsHeaderUk.svg'; // done needs tlc
import { closeModal } from '../../actions/modal';
import { closeNavigation } from '../../actions/navigation';
import ModalHeaderDailyChallenge from '../DailyChallengeExplanationModal/DailyChallengeHeader.svg'; // done
import ModalHeaderDailyChallengeUk from '../DailyChallengeExplanationModal/DailyChallengeHeaderUk.svg'; // done
import { PortalWithState } from '../Portal';
import FullPageModal from './FullPageModal';
import GameModalContainer from './GameModalContainer';
// Get the types of modals
import PseudoModal from './PseudoModal';

import s from './RootModal.scss';
import GameBrowserSearchModal from '../GameBrowser/GameBrowserSearchModal';

// Get the specific modals
// import AuthModal from '../AuthModal';
// import ForgotPasswordModal from '../ForgotPasswordModal';

// import XpMissionProgressModal from '../XpMissionProgressModal';
// import XpCollectionProgressModal from '../XpCollectionProgressModal';
// import MaxBetModal from '../MaxBetModal';
// import RealityCheckModal from '../RealityCheckModal';
// import SeparateFundsModal from '../SeparateFundsModal';
// import GameModal from '../GameModal';

// import RewardBrowserModal from '../RewardBrowserModal';
// import TermsAndConditionsModal from '../TermsAndConditionsModal';
// import BonusTermsAndConditionsModal from '../BonusTermsAndConditionsModal';
// import XpMissionInformationModal from '../XpMissionInformationModal';
// import XpMissionLockedInformationModal from '../XpMissionLockedInformationModal';

// import XperienceTracksFinishedModal from '../XperienceTracksFinishedModal';
// import XperienceTracksProgressModal from '../XperienceTracksProgressModal';
// import XperienceTrackChooseModal from '../XperienceTrackChooseModal';
// import AcceptTermsAndConditionsModal from '../AcceptTermsAndConditionsModal';
// import WelcomeModal from '../Register/WelcomeModal';
// import DepositModal from '../DepositModal';
// import ChooseDepositMethodModal from '../Deposit/ChooseDepositMethodModal';
// import ChooseBonusModal from '../Deposit/ChooseBonusModal';
// import WithdrawModal from '../WithdrawModal';
// import WithdrawPendingModal from '../WithdrawPendingModal';
// import ChooseWithdrawMethodModal from '../Withdraw/ChooseWithdrawMethodModal';

// @todo implement
// import RequestQuotaModal from '../DepositModal';

/*
  REQUEST_QUOTA_MODAL: RequestQuotaModal,
*/

@connect(
  (state) => ({
    licenseType: state.license.type,
    isAuthenticated: state.auth.isAuthenticated,
  }),
  (dispatch) => ({
    closeModal: (modalName) => dispatch(closeModal(modalName)),
    closeNavigation: () => dispatch(closeNavigation()),
  }),
)
@withStyles(s)
class RootModal extends React.Component {
  constructor(props) {
    super(props);
    this.childModal = null;
    this.state = {
      SpecificModal: null,
    };
    // this.getChildRef = this.getChildRef.bind(this)
  }

  componentDidMount() {
    const {
      licenseType,
      isAuthenticated,
      modal: { modalName },
    } = this.props;

    const MODAL_COMPONENTS = {
      /* this one is for mobile, after finishing a game */
      XPMISSION_GAME_COMPLETE_MODAL: {
        component: import(
          /* webpackChunkName: "MissionComplete" */ '../GameModal/MissionComplete'
        ),
        type: FullPageModal,
        closeOnEsc: true,
        hasClose: false,
        theme: 'blue',
        modalHeader: '',
      },
      // big todo
      DAILY_CHALLENGE_EXPLANATION: {
        component: import(
          /* webpackChunkName: "DailyChallengeExplanationModal" */ '../DailyChallengeExplanationModal'
        ),
        type: PseudoModal,
        closeOnEsc: true,
        hasClose: true,
        modalHeaderUk: ModalHeaderDailyChallengeUk,
        modalHeader: ModalHeaderDailyChallenge,
        theme: 'contentModal',
      },
      // GDPR_MAIL_CONSENT: {
      //   component: import(
      //     /* webpackChunkName: "GdprMailConsentModal" */ '../GdprMailConsentModal'
      //   ),
      //   type: PseudoModal,
      //   closeOnEsc: true,
      //   hasClose: false,
      //   modalHeaderUk: ModalHeaderTermsUk,
      //   modalHeader: ModalHeaderTerms,
      //   theme: 'contentModal',
      // },
      // // big todo
      // ACCEPT_TRANSFER_DATA_MODAL: {
      //   component: import(
      //     /* webpackChunkName: "TransferDataModal" */ '../TransferDataModal'
      //   ),
      //   type: PseudoModal,
      //   closeOnEsc: true,
      //   hasClose: false,
      //   modalHeaderUk: ModalHeaderTermsUk,
      //   modalHeader: ModalHeaderTerms,
      // },
      LOYALTY_TERMS_MODAL: {
        component: import(
          /* webpackChunkName: "LoyaltyTermsModal" */ '../LoyaltyTermsModal'
        ),
        type: PseudoModal,
        closeOnEsc: true,
        hasClose: true,
        modalHeaderUk: ModalHeaderTermsUk,
        modalHeader: ModalHeaderTerms,
      },
      // ACCEPT_TERMS_AND_CONDITIONS_MODAL: {
      //   component: import(
      //     /* webpackChunkName: "AcceptTermsAndConditionsModal" */ '../AcceptTermsAndConditionsModal'
      //   ),
      //   type: PseudoModal,
      //   closeOnEsc: true,
      //   hasClose: false,
      //   modalHeaderUk: ModalHeaderTermsUk,
      //   modalHeader: ModalHeaderTerms,
      // },
      // THIRD_PARTY_MODAL: {
      //   component: import(
      //     /* webpackChunkName: "ThirdPartyModal" */ '../ThirdPartyModal'
      //   ),
      //   type: PseudoModal,
      //   closeOnEsc: true,
      //   hasClose: true,
      //   modalHeaderUk: ModalHeaderTermsUk,
      //   modalHeader: ModalHeaderTerms,
      // },
      // AUTH_MODAL: {
      //   component: import(/* webpackChunkName: "AuthModal" */ '../AuthModal'),
      //   type: PseudoModal,
      //   closeOnEsc: true,
      //   hasClose: true,
      //   modalHeader: ModalHeaderAuth,
      //   modalHeaderUk: ModalHeaderAuthUk,
      //   theme: 'contentModal',
      // },
      // REGISTER_MODAL: {
      //   component: RegisterForm,
      //   type: PseudoModal,
      //   closeOnEsc: true,
      //   hasClose: true,
      //   // modalHeader: ModalHeaderAuth,
      //   // modalHeaderUk: ModalHeaderAuthUk,
      //   newUI: true,
      //   theme: 'contentModal',
      // },
      // FORGOT_PASSWORD_MODAL: {
      //   component: import(
      //     /* webpackChunkName: "ForgotPasswordModal" */ '../ForgotPasswordModal'
      //   ),
      //   type: PseudoModal,
      //   closeOnEsc: true,
      //   hasClose: true,
      //   modalHeader: ModalHeaderAuth,
      //   modalHeaderUk: ModalHeaderAuthUk,
      //   theme: 'contentModal',
      // },
      SEPARATE_FUNDS_MODAL: {
        component: import(
          /* webpackChunkName: "SeparateFundsModal" */ '../SeparateFundsModal'
        ),
        type: PseudoModal,
        closeOnEsc: false,
        hasClose: false,
        modalHeader: ModalHeaderAuthUk,
        theme: 'contentModal',
      },

      // REALITY_CHECK_MODAL: {
      //   component: import(
      //     /* webpackChunkName: "RealityCheckModal" */ '../reality-check-modal'
      //   ),
      //   type: PseudoModal,
      //   closeOnEsc: false,
      //   hasClose: false,
      //   modalHeader: ModalHeaderRealityCheck,
      //   theme: 'contentModal',
      // },
      // REALITY_CHECK_SET_MODAL: {
      //   component: import(
      //     /* webpackChunkName: "RealityCheckModal" */ '../RealityCheckSetModal'
      //   ),
      //   type: PseudoModal,
      //   closeOnEsc: false,
      //   hasClose: false,
      //   modalHeader: ModalHeaderRealityCheck,
      //   theme: 'contentModal',
      // },
      GAME_MODAL: {
        component: import(/* webpackChunkName: "GameModal" */ '../GameModal'),
        type: GameModalContainer,
        closeOnEsc: true,
        hasClose: true,
      },
      // OVER_BET_MODAL: {
      //   component: import(
      //     /* webpackChunkName: "OverBetModal" */ '../OverBetModal'
      //   ),
      //   type: PseudoModal,
      //   closeOnEsc: false,
      //   hasClose: false,
      //   modalHeader: ModalHeaderOverBet,
      //   theme: 'contentModal',
      // },
      CONTACT_MODAL: {
        component: import(
          /* webpackChunkName: "ContactModal" */ '../ContactModal'
        ),
        type: PseudoModal,
        closeOnEsc: true,
        hasClose: true,
        modalHeader: ModalHeaderTermsUk,
        theme: 'contentModal',
      },
      // UK_CLOSE: {
      //   component: import(/* webpackChunkName: "EmailModal" */ '../EmailModal'),
      //   type: PseudoModal,
      //   closeOnEsc: false,
      //   hasClose: false,
      //   modalHeader: ModalHeaderTermsUk,
      //   theme: 'contentModal',
      // },
      // MAX_BET_MODAL: {
      //   component: import(
      //     /* webpackChunkName: "MaxBetModal" */ '../MaxBetModal'
      //   ),
      //   type: PseudoModal,
      //   closeOnEsc: false,
      //   hasClose: false,
      //   modalHeader: ModalHeaderMaxBet,
      //   theme: 'contentModal',
      // },
      // NO_BONUS_MODAL: {
      //   component: import(
      //     /* webpackChunkName: "NoBonusModal" */ '../NoBonusModal'
      //   ),
      //   type: PseudoModal,
      //   closeOnEsc: false,
      //   hasClose: false,
      //   modalHeader: ModalHeaderMaxBet,
      //   theme: 'contentModal',
      // },
      // WELCOME_PROMOTION_MODAL: {
      //   component: import(
      //     /* webpackChunkName: "WelcomePromotionModal" */ '../WelcomePromotionModal'
      //   ),
      //   type: PseudoModal,
      //   closeOnEsc: true,
      //   hasClose: true,
      //   modalHeader: ModalHeaderMaxBet,
      //   theme: 'contentModal',
      // },
      // DEPOSIT_MODAL: {
      //   component: import(
      //     /* webpackChunkName: "DepositModal" */ '../DepositModal'
      //   ),
      //   type: PseudoModal,
      //   closeOnEsc: true,
      //   hasClose: true,
      //   modalHeader: ModalHeaderDeposit,
      //   theme: 'smallHeader',
      // },
      // CHOOSE_DEPOSIT_METHOD_MODAL: {
      //   component: import(
      //     /* webpackChunkName: "ChooseDepositMethodModal" */ '../Deposit/ChooseDepositMethodModal'
      //   ),
      //   type: PseudoModal,
      //   closeOnEsc: true,
      //   hasClose: false,
      //   modalHeader: ModalHeaderDeposit,
      //   theme: 'smallHeader',
      // },
      // CHOOSE_BONUS_MODAL: {
      //   component: import(
      //     /* webpackChunkName: "ChooseBonusModal" */ '../Deposit/ChooseBonusModal'
      //   ),
      //   type: PseudoModal,
      //   closeOnEsc: true,
      //   hasClose: false,
      //   modalHeader: ModalHeaderDeposit,
      //   theme: 'smallHeader',
      // },
      TERMS_AND_CONDITIONS_MODAL: {
        component: import(
          /* webpackChunkName: "TermsAndConditionsModal" */ '../TermsAndConditionsModal'
        ),
        type: PseudoModal,
        closeOnEsc: true,
        hasClose: true,
        modalHeaderUk: ModalHeaderTermsUk,
        modalHeader: ModalHeaderTerms,
        theme: 'contentModal',
      },
      BONUS_TERMS_AND_CONDITIONS_MODAL: {
        component: import(
          /* webpackChunkName: "BonusTermsAndConditionsModal" */ '../BonusTermsAndConditionsModal'
        ),
        type: PseudoModal,
        closeOnEsc: true,
        hasClose: true,
        modalHeaderUk: ModalHeaderTermsUk,
        modalHeader: ModalHeaderTerms,
        theme: 'contentModal',
      },
      // KYC_MODAL: {
      //   component: import(/* webpackChunkName: "KycModal" */ '../KycModal'),
      //   type: PseudoModal,
      //   closeOnEsc: true,
      //   hasClose: true,
      //   modalHeaderUk: ModalHeaderTermsUk,
      //   modalHeader: ModalHeaderTerms,
      //   theme: 'contentModal',
      // },
      // WITHDRAW_MODAL: {
      //   component: import(
      //     /* webpackChunkName: "WithdrawModal" */ '../WithdrawModal'
      //   ),
      //   type: PseudoModal,
      //   closeOnEsc: true,
      //   hasClose: true,
      //   modalHeader: ModalHeaderWithdraw,
      //   theme: 'smallHeader',
      // },
      // CHOOSE_WITHDRAW_METHOD_MODAL: {
      //   component: import(
      //     /* webpackChunkName: "ChooseWithdrawMethodModal" */ '../Withdraw/ChooseWithdrawMethodModal'
      //   ),
      //   type: PseudoModal,
      //   closeOnEsc: true,
      //   hasClose: false,
      //   modalHeader: ModalHeaderWithdraw,
      //   theme: 'smallHeader',
      // },
      // WITHDRAW_PENDING_MODAL: {
      //   component: import(
      //     /* webpackChunkName: "WithdrawPendingModal" */ '../WithdrawPendingModal'
      //   ),
      //   type: PseudoModal,
      //   closeOnEsc: true,
      //   hasClose: true,
      //   modalHeader: ModalHeaderWithdraw,
      // },

      // REWARD_BROWSER_MODAL: {
      //   component: import(
      //     /* webpackChunkName: "RewardBrowserModal" */ '../RewardBrowserModal'
      //   ),
      //   type: PseudoModal,
      //   closeOnEsc: true,
      //   hasClose: true,
      //   modalHeader: ModalHeaderRewards,
      // },
      // REWARD_BROWSER_DEPOSIT_MODAL: {
      //   component: import(
      //     /* webpackChunkName: "RewardBrowserModal" */ '../RewardBrowserModal'
      //   ),
      //   type: PseudoModal,
      //   closeOnEsc: true,
      //   hasClose: true,
      //   modalHeader: ModalHeaderRewards,
      //   theme: 'smallHeader',
      // },
      GAME_CATEGORY_MODAL: {
        component: import(
          /* webpackChunkName: "CategorySelectionModal" */ '../GameBrowser/CategorySelectionModal'
        ),
        type: PseudoModal,
        closeOnEsc: true,
        hasClose: true,
      },
      GAME_SEARCH_MODAL: {
        component: GameBrowserSearchModal,
        type: PseudoModal,
        closeOnEsc: true,
        hasClose: true,
      },

      // REQUEST_QUOTA_MODAL: {
      //   component: import(
      //     /* webpackChunkName: "RequestQuotaModal" */ '../RequestQuotaModal'
      //   ),
      //   type: PseudoModal,
      //   closeOnEsc: false,
      //   hasClose: false,
      //   modalHeader: ModalHeaderMaxBet,
      //   theme: 'contentModal',
      // },

      /*
          WELCOME_MODAL: {
            component: WelcomeModal,
            type: PseudoModal,
            closeOnEsc: false,
            hasClose: false,
            modalHeader: ModalHeaderGeneral,
          },
          */
    };

    const SpecificModal = MODAL_COMPONENTS[modalName].component;
    if (Promise.resolve(SpecificModal) == SpecificModal) {
      Promise.resolve(SpecificModal).then((SpecificModal) => {
        this.setState({
          SpecificModal: SpecificModal.default,
          SpecificType: MODAL_COMPONENTS[modalName].type,
          CloseOnEsc: MODAL_COMPONENTS[modalName].closeOnEsc,
          HasClose: MODAL_COMPONENTS[modalName].hasClose,
          ModalHeader:
            _.has(MODAL_COMPONENTS[modalName], 'modalHeaderUk') &&
            licenseType === 'UK' &&
            !isAuthenticated
              ? MODAL_COMPONENTS[modalName].modalHeaderUk
              : MODAL_COMPONENTS[modalName].modalHeader,
          Theme: MODAL_COMPONENTS[modalName].theme,
          node: MODAL_COMPONENTS[modalName].node
            ? MODAL_COMPONENTS[modalName].node
            : false,
          newUI: MODAL_COMPONENTS[modalName].newUI,
        });
      });
    } else {
      this.setState({
        SpecificModal,
        SpecificType: MODAL_COMPONENTS[modalName].type,
        CloseOnEsc: MODAL_COMPONENTS[modalName].closeOnEsc,
        HasClose: MODAL_COMPONENTS[modalName].hasClose,
        ModalHeader:
          _.has(MODAL_COMPONENTS[modalName], 'modalHeaderUk') &&
          licenseType === 'UK' &&
          !isAuthenticated
            ? MODAL_COMPONENTS[modalName].modalHeaderUk
            : MODAL_COMPONENTS[modalName].modalHeader,
        Theme: MODAL_COMPONENTS[modalName].theme,
        node: MODAL_COMPONENTS[modalName].node
          ? MODAL_COMPONENTS[modalName].node
          : false,
        newUI: MODAL_COMPONENTS[modalName].newUI,
      });
    }
  }

  render() {
    const {
      closeModal,
      licenseType,
      isAuthenticated,
      modal: { modalName, isOpen, modalProps },
    } = this.props;

    const {
      SpecificType,
      CloseOnEsc,
      HasClose,
      ModalHeader,
      Theme,
      node,
      newUI,
    } = this.state;

    let { SpecificModal } = this.state;

    if (!modalName) {
      return null;
    }

    if (!SpecificModal) {
      return null;
    }
    if (SpecificModal.default) {
      SpecificModal = SpecificModal.default;
    }
    return (
      <PortalWithState
        node={node}
        defaultOpen
        className={cx(s.root, 'modal')}
        closeOnEsc={CloseOnEsc}
        onClose={() => closeModal(modalName)}
        {...modalProps}
      >
        {({ openPortal, closePortal, isOpen, portal }) =>
          portal(
            <SpecificType
              {...modalProps}
              closePortal={closePortal}
              hasClose={HasClose}
              modalRef={this.getChildRef}
              modalName={modalName}
              modalHeader={
                _.has(modalProps, 'header') ? modalProps.header : ModalHeader
              }
              modalTheme={Theme}
              newUI={newUI}
            >
              <SpecificModal {...modalProps} closePortal={closePortal} />
            </SpecificType>,
          )
        }
      </PortalWithState>
    );
  }
}

export default RootModal;
