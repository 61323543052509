// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BottomBarMenuItemNavigation-root-_1YMl{height:40px;z-index:2;will-change:transform;text-decoration:none!important;color:var(--color-white);box-sizing:border-box;position:relative;transition:all .5s;display:flex;flex-direction:column;justify-content:space-between;align-items:center;width:auto}.BottomBarMenuItemNavigation-root-_1YMl>svg{height:27px;min-width:27px}", ""]);
// Exports
exports.locals = {
	"root": "BottomBarMenuItemNavigation-root-_1YMl"
};
module.exports = exports;
