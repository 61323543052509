export function updateTag(tagName, keyName, keyValue, attrName, attrValue) {
  if (!process.env.BROWSER) return;
  const node = document.head.querySelector(
    `${tagName}[${keyName}='${keyValue}']`,
  );
  if (node && node.getAttribute(attrName) === attrValue) return;

  // Remove and create a new tag in order to make it work with bookmarks in Safari
  if (node) {
    node.parentNode.removeChild(node);
  }
  if (typeof attrValue === 'string') {
    const nextNode = document.createElement(tagName);
    nextNode.setAttribute(keyName, keyValue);
    if (keyValue === 'canonical') {
      nextNode.setAttribute(attrName, `https://www.playfrank.com${attrValue}`);
    } else {
      nextNode.setAttribute(attrName, attrValue);
    }
    document.head.appendChild(nextNode);
  }
}

export function updateMeta(name, content) {
  updateTag('meta', 'name', name, 'content', content);
}

export function updateCustomMeta(property, content) {
  updateTag('meta', 'property', property, 'content', content);
}

export function updateLink(rel, href) {
  console.log(`updateLink : `, rel, href);
  updateTag('link', 'rel', rel, 'href', href);
}

export function updateTitle(title) {
  if (process.env.BROWSER) document.title = title;
  if (process.env.BROWSER) updateCustomMeta('og:title', title);
}

export function updateAlternate(hreflang) {
  if (!process.env.BROWSER) return;
  Array.from(document.head.querySelectorAll('link[rel=alternate]')).forEach(
    node => {
      if (node) {
        node.parentNode.removeChild(node);
      }
    },
  );

  if (typeof hreflang === 'object') {
    Object.keys(hreflang).forEach(key => {
      if (typeof hreflang[key] === 'string') {
        const nextNode = document.createElement('link');
        nextNode.setAttribute('rel', 'alternate');
        nextNode.setAttribute('href', hreflang[key]);
        nextNode.setAttribute('hreflang', key);
        document.head.appendChild(nextNode);
      }
    });

    // Also set a default
    if (typeof hreflang.en === 'string') {
      const nextNode = document.createElement('link');
      nextNode.setAttribute('rel', 'alternate');
      nextNode.setAttribute('href', hreflang.en);
      nextNode.setAttribute('hreflang', 'x-default');
      document.head.appendChild(nextNode);
    }
  }
}
