import cx from 'classnames';
import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { trackClick } from '../../../actions/analytics';
import { setGameCategory } from '../../../actions/gamebrowser';
import { startAuthentication } from '../../../actions/auth';
import Link from '../../Link';
import MenuItem from '../MenuItem';
import chat from './chat.svg';
// icons
import lobby from './play.svg';
import promotions from './promotions.svg';
import dc from './dc.svg';
import liveMenu from './liveMenu.svg';

const messages = defineMessages({
  signup: {
    id: 'signup',
    defaultMessage: 'Signup',
    description: 'General word',
  },
  liveChat: {
    id: 'liveChat',
    defaultMessage: 'Live Chat',
    description: 'livechat',
  },
  games: {
    id: 'games',
    defaultMessage: 'Games',
    description: 'General word',
  },
  casino: {
    id: 'casino',
    description: 'Casino',
    defaultMessage: 'Casino',
  },
  live: {
    id: 'text.live',
    defaultMessage: 'Game Shows',
    description: 'The category name for te gambrowser navigator',
  },
  login: {
    id: 'login',
    defaultMessage: 'Login',
    description: 'General word',
  },
  blog: {
    id: 'blog',
    defaultMessage: 'Blog',
    description: 'General word',
  },
  dailyChallengesTitle: {
    id: 'dailyChallengesTitle',
    defaultMessage: 'Daily Challenges',
    description: 'Name of the xp missions',
  },
  slugLiveLobby: {
    id: 'slug.liveLobby',
    defaultMessage: 'live-lobby',
    description: 'The SEO description for the home page',
  },
  slugLobby: {
    id: 'slug.lobby',
    defaultMessage: 'lobby',
    description: 'The SEO description for the home page',
  },
});

@connect(
  (state) => ({
    navigation: state.navigation,
    isGameBrowserActive: state.navigation.isGameBrowserActive,
    activeRouteName: state.router.activeRoute.name,
    lobbyType: state.router.activeRoute.params.lobbyType,
    IpCountry: state.user.IpCountry,
    selectedSubCategory: state.gamebrowser.selectedSubCategory,
  }),
  (dispatch) => {
    return {
      setGameCategory: () => dispatch(setGameCategory('', '')),
      startAuthentication: () => {
        dispatch(startAuthentication());
        dispatch(
          trackClick('Login', { component: 'Sidebar', variation: 'Default' }),
        );
      },
      trackClick: (action, params) => dispatch(trackClick(action, params)),
    };
  },
)
@injectIntl
class SideBarMenuPublic extends React.Component {
  constructor(props) {
    super(props);
    this.trackRegister = this.trackRegister.bind(this);
    this.state = { hide: false, counter: null };
  }

  trackRegister() {
    this.props.trackClick('Register', {
      component: 'Sidebar',
      variation: 'Default',
    });
  }

  render() {
    const {
      navigation,
      isGameBrowserActive,
      startAuthentication,
      intl,
      activeRouteName,
      lobbyType,
      IpCountry,
      setGameCategory,
      selectedSubCategory,
    } = this.props;

    const { counter } = this.state;

    return (
      <>
        <div className="px-2.5 pt-8 space-y-3 max-w-[200px] mx-auto w-full">
          <Link
            className="btn btn--primary  w-full btn--lg"
            to="register"
            data-id="register"
          >
            {intl.formatMessage(messages.signup)}
          </Link>

          <Link
            to="auth"
            transition={false}
            className="btn btn--white-outline w-full btn--lg"
            onClick={() => {
              startAuthentication();
              this.trackRegister();
            }}
            data-id="login"
          >
            {intl.formatMessage(messages.login)}
          </Link>
        </div>
        <div
          className="space-y-2 mt-12 lg:max-w-none max-w-[200px] w-full mx-auto"
          ref={(el) => {
            this.menuContainer = el;
          }}
        >
          <MenuItem
            to="home"
            onClick={setGameCategory}
            title=""
            icon={lobby}
            targetHash="gameBrowser"
            className={cx({
              'bg-white/40':
                isGameBrowserActive &&
                lobbyType === 'general' &&
                selectedSubCategory !== 'game-shows',
            })}
          >
            {intl.formatMessage(messages.casino)}
          </MenuItem>

          {IpCountry !== 'DE' && (
            <MenuItem
              to="lobby"
              params={{ lobby: 'lobby', category: 'game-shows' }}
              title=""
              icon={liveMenu}
              className={cx({
                'bg-white/40':
                  isGameBrowserActive && selectedSubCategory === 'game-shows',
              })}
            >
              {intl.formatMessage(messages.live)}
            </MenuItem>
          )}
          {IpCountry !== 'DE' && (
            <MenuItem
              to="dailyChallenge"
              title=""
              icon={dc}
              className={cx({
                'bg-white/40': activeRouteName === 'dailyChallenge',
              })}
            >
              {intl.formatMessage(messages.dailyChallengesTitle)}
            </MenuItem>
          )}
          {IpCountry !== 'DE' && (
            <MenuItem
              to="promotion"
              title="promotion"
              icon={promotions}
              counter={counter}
              className={cx({
                'bg-white/40':
                  activeRouteName === 'promotion' ||
                  activeRouteName === 'promotionDetail',
              })}
            >
              {intl.formatMessage(messages.blog)}
            </MenuItem>
          )}
          <MenuItem
            title="chat"
            icon={chat}
            onClick={(e) => {
              e.preventDefault();
              if (window?.AsgFW) {
                window.AsgFW.showChat();
              }
            }}
          >
            {intl.formatMessage(messages.liveChat)}
          </MenuItem>
        </div>
      </>
    );
  }
}
export default SideBarMenuPublic;
