import React from 'react';

import { Menu, Transition } from '@headlessui/react';

/* eslint-disable react/prop-types */
import cx from 'classnames';
import { defineMessages, useIntl } from 'react-intl';

import euFlag from '../../../public/img/flags/eu.svg?external';
import gbFlag from '../../../public/img/flags/gb.svg?external';
import fiFlag from '../../../public/img/flags/fi.svg?external';
import deFlag from '../../../public/img/flags/de.svg?external';
import caFlag from '../../../public/img/flags/ca.svg?external';
import inFlag from '../../../public/img/flags/in.svg?external';
import nzFlag from '../../../public/img/flags/nz.svg?external';
import { locales } from '../../config';

const localeFlags = {
  'en-gb': gbFlag,
  'en-ca': caFlag,
  'en-in': inFlag,
  // "en-za": zaFlag,
  'en-nz': nzFlag,
  en: euFlag,
  fi: fiFlag,
  // sv: svFlag,
  de: deFlag,
  // no: noFlag,
};

const messages = defineMessages({
  default: {
    id: 'EN',
    defaultMessage: 'EN',
    description: 'languageswitchter alt text',
  },
  'en-gb': {
    id: 'GB',
    defaultMessage: 'GB',
    description: 'languageswitchter alt text',
  },
  en: {
    id: 'EN',
    defaultMessage: 'EN',
    description: 'languageswitchter alt text',
  },
  de: {
    id: 'DE',
    defaultMessage: 'DE',
    description: 'languageswitchter alt text',
  },
  'en-ca': {
    id: 'CA',
    defaultMessage: 'CA',
    description: 'languageswitchter alt text',
  },
  'en-nz': {
    id: 'NZ',
    defaultMessage: 'NZ',
    description: 'languageswitchter alt text',
  },
  'en-in': {
    id: 'in',
    defaultMessage: 'IN',
    description: 'languageswitchter alt text',
  },
  'en-za': {
    id: 'za',
    defaultMessage: 'ZA',
    description: 'languageswitchter alt text',
  },
});

export const LanguageSwitcher = () => {
  const intl = useIntl();

  return (
    <Menu className="relative">
      {({ open }) => (
        <>
          <Menu.Button className="flex items-center space-x-2">
            <img
              src={localeFlags[intl.locale]}
              className="rounded-full overflow-hidden w-5 h-5"
              alt=""
            />
            <span className="text-sm font-semibold">
              {intl.formatMessage(
                Object.hasOwnProperty.call(messages, intl.locale)
                  ? messages[intl.locale]
                  : messages.default,
              )}
            </span>
          </Menu.Button>
          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
            className="absolute bottom-10 left-0  bg-white/20 w-full z-10 backdrop-blur-sm rounded-t"
          >
            {locales &&
              locales.map(
                (locale) =>
                  Object.hasOwnProperty.call(localeFlags, locale) && (
                    <div
                      key={locale}
                      className={cx(
                        ' flex items-center w-full hover:bg-white/20  px-3 py-1.5 cursor-pointer transition-all space-x-2',
                        {
                          'bg-white/30': intl.locale === locale,
                        },
                      )}
                      onClick={(e) => {
                        getData(locale);
                        e.preventDefault();
                      }}
                    >
                      <img
                        src={localeFlags[locale]}
                        className="rounded-full overflow-hidden w-7 h-7 object-cover"
                        alt=""
                        loading="lazy"
                      />
                      <span className="text-sm font-semibold">
                        {intl.formatMessage(
                          Object.hasOwnProperty.call(messages, locale)
                            ? messages[locale]
                            : messages.default,
                        )}
                      </span>
                    </div>
                  ),
              )}
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default LanguageSwitcher;
