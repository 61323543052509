// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ToggleNavigation-root-o4Dd4{position:fixed;top:0;left:0;height:50px;background-image:linear-gradient(180deg,#004f7d 0,#358fb8);z-index:35;will-change:transform;filter:drop-shadow(0 7px 6px rgba(0,0,0,.25));box-sizing:border-box}.ToggleNavigation-root-o4Dd4 a{color:#fff;text-decoration:none}@media screen and (--screen-md-min){#app{padding-top:50px}}.ToggleNavigation-burger-1d5fB{transition:transform .3}.ToggleNavigation-burger-1d5fB svg{width:40px;height:26px;cursor:pointer;transform:translateZ(0);margin-right:-12px}.ToggleNavigation-burger-1d5fB path{fill:none;transition:stroke-dashoffset .5s cubic-bezier(.25,-.25,.75,1.25),stroke-dasharray .5s cubic-bezier(.25,-.25,.75,1.25);stroke-width:40px;stroke-linecap:round;stroke:var(--color-white);stroke-dashoffset:0px}.ToggleNavigation-burger-1d5fB .ToggleNavigation-bottom-xqoG7,.ToggleNavigation-burger-1d5fB .ToggleNavigation-top-2lohT{stroke-dasharray:240px 950px}.ToggleNavigation-burger-1d5fB .ToggleNavigation-middle-2LTav{stroke-dasharray:240px 240px}.ToggleNavigation-cross-2A9VM .ToggleNavigation-bottom-xqoG7,.ToggleNavigation-cross-2A9VM .ToggleNavigation-top-2lohT{stroke-dashoffset:-650px}.ToggleNavigation-cross-2A9VM .ToggleNavigation-middle-2LTav{stroke-dashoffset:-115px;stroke-dasharray:1px 220px}", ""]);
// Exports
exports.locals = {
	"root": "ToggleNavigation-root-o4Dd4",
	"burger": "ToggleNavigation-burger-1d5fB",
	"bottom": "ToggleNavigation-bottom-xqoG7",
	"top": "ToggleNavigation-top-2lohT",
	"middle": "ToggleNavigation-middle-2LTav",
	"cross": "ToggleNavigation-cross-2A9VM"
};
module.exports = exports;
