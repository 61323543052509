import React from 'react';
import {defineMessages, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import autobind from 'autobind-decorator';
import parseISO from 'date-fns/parseISO';
import isAfter from 'date-fns/isAfter';
import withStyles from 'isomorphic-style-loader/withStyles';
import {Transition} from '@headlessui/react';
import plus from '../../../public/img/icons/plus-circle.svg';
import BottomBarMenuItem from './BottomBarMenuItem';

import BottomBarMenuItemNavigation from './BottomBarMenuItemNavigation';
import {openModal} from '../../actions/modal';
import {trackAction} from '../../actions/analytics';
import {find} from '../../core/cockpit';
import s from './BottomBarMenuPublic.scss';
import Link from '../Link';
import {startDeposit} from '../../actions/deposit';
import SideBarClock from '../SideBar/SideBarClock';
import blog2 from './promotions.svg';
import bottomBgmask from './bottom-menu-bg-mask.svg';
import gift from './gift.png';
import lights from './lights.png';

const messages = defineMessages({
  slugLiveLobby: {
    id: 'slug.liveLobby',
    defaultMessage: 'live-lobby',
    description: 'The SEO description for the home page',
  },
  live: {
    id: 'text.live',
    defaultMessage: 'Live casino',
    description: 'The category name for te gambrowser navigator',
  },
  promotion: {
    id: 'promotion',
    description: 'promotions',
    defaultMessage: 'Promotions',
  },
  menu: {
    id: 'menu',
    description: 'Menu',
    defaultMessage: 'Menu',
  },
});

@connect(
  state => ({
    isGameBrowserActive: state.navigation.isGameBrowserActive,
    browser: state.browser,
    activeRouteName: state.router.activeRoute.name,
    lobbyType: state.router.activeRoute.params.lobbyType,
    IpCountry: state.user.IpCountry,
    wallet: state.wallet,
    isNavigationOpen: state.navigation.isNavigationOpen,
  }),
  dispatch => ({
    openGameCategoryModal: () => dispatch(openModal('GAME_CATEGORY_MODAL')),
    openGameSearchModal: () => dispatch(openModal('GAME_SEARCH_MODAL')),
    openGameFilterModal: () => dispatch(openModal('GAME_FILTER_MODAL')),
    trackAction: (event, data) => dispatch(trackAction(event, data)),
    startDeposit: () => {
      dispatch(startDeposit());
      dispatch(
        trackAction('Start Deposit', {
          variation: 'Default',
          component: 'BottomBarMenuAuthorized',
        }),
      );
    },
  }),
)
@injectIntl
@withStyles(s)
class BottomBarMenuPublic extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { counter: null };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeRouteName === 'promotion') {
      this.fetchData();
    }
  }

  @autobind
  fetchData() {
    const { intl } = this.props;
    let counter = 0;
    find('promotions', { active: 1, activeAspire: 1 }, 300, 0, intl.locale, 0, {
      weight: -1,
    }).then(result => {
      const lastVisit = localStorage.getItem('LAST_VISIT_BLOG');

      console.log('lastVisit', lastVisit);
      if (!lastVisit) {
        console.log('default');
        this.setState({ counter: result.entries.length });
      } else {
        console.log('calc');
        result.entries.map(promo => {
          if (
            isAfter(
              Date.parse(parseISO(promo.startdate)),
              Date.parse(lastVisit),
            )
          ) {
            console.log('counter');
            counter++;
          }
        });
        this.setState({ counter });
      }
    });
  }

  openChat() {
    if (window.fcWidget) {
      window.fcWidget.open();
    }
  }

  render() {
    const {
      intl,
      isGameBrowserActive,
      activeRouteName,
      lobbyType,
      openGameSearchModal,
      openGameCategoryModal,
      IpCountry,
      startDeposit,
      wallet,
      isNavigationOpen,
    } = this.props;
    const { counter } = this.state;

    return (
      <>
        <div className="flex items-center space-x-3">
          <div>
            <BottomBarMenuItemNavigation
              label={intl.formatMessage(messages.menu)}
            />
          </div>
          {!isNavigationOpen && (
            <BottomBarMenuItem
              to="promotion"
              title=""
              icon={blog2}
              targetHash="gameBrowser"
              active={
                activeRouteName === 'promotion' ||
                activeRouteName === 'promotionDetail'
              }
              label={intl.formatMessage(messages.promotion)}
            />
          )}
        </div>
        <Transition
          show={!isNavigationOpen}
          enter="transition duration-500 ease-out"
          enterFrom="transform translate-y-5 opacity-0"
          enterTo="transform translate-y-0 opacity-100"
          leave="transition duration-500 ease-out"
          leaveFrom="transform translate-y-0 opacity-100"
          leaveTo="transform translate-y-5 opacity-0"
        >
          <div className="relative h-full w-20 mx-auto flex items-center justify-center">
            <img
              src={bottomBgmask}
              alt=""
              className="w-full  absolute bottom-0"
            />
            <img
              src={lights}
              alt=""
              className="absolute mix-blend-screen animate-spin w-[76px]"
            />
            <img src={gift} alt="" className="w-auto relative -mt-2   " />
          </div>
        </Transition>
        {!isNavigationOpen && (
          <>
            {/* {IpCountry !== 'DE' && (
              <BottomBarMenuItem
                to="lobby"
                params={{ lobby: 'lobby', category: 'game-shows' }}
                title=""
                icon={liveMenu}
                active={activeRouteName === 'lobby'}
                label={intl.formatMessage(messages.live)}
              />
            )} */}
            <div className=" flex items-center justify-end">
              <Link
                className="btn btn--primary !pr-10 !pl-2"
                data-id="deposit"
                onClick={startDeposit}
              >
                {intl.formatNumber(wallet.total, {
                  style: 'currency',
                  currencyDisplay: 'symbol',
                  currency: wallet.baseCurrency,
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}

                <svg className="w-6 h-6 ml-1 absolute right-2 top-0.5">
                  <use xlinkHref={`${plus}#root`} />
                </svg>
              </Link>
            </div>
          </>
        )}

        {isNavigationOpen && (
          <div className="flex items-center justify-end col-span-2">
            <SideBarClock />
            {/* <div>
              <a
                onClick={e => {
                  e.preventDefault();
                  if (window?.AsgFW) {
                    window.AsgFW.showChat();
                  }
                }}
                type="button"
              >
                <img src={chat} alt="chat" />
              </a>
            </div> */}
          </div>
        )}
      </>
    );
  }
}

export default BottomBarMenuPublic;
