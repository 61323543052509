import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import React from 'react';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {toggleNavigation} from '../../actions/navigation';
import s from './ToggleNavigation.scss';

@connect(
  (state) => {
    return {
      isNavigationOpen: state.navigation.isNavigationOpen,
    };
  },
  (dispatch) => {
    return {
      toggleNavigation: () => dispatch(toggleNavigation()),
    };
  },
)
class ToggleNavigation extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  toggleNavigation(e) {
    this.props.toggleNavigation();
    e.preventDefault();
  }

  render() {
    const {
      isNavigationOpen,
      toggleNavigation,
      className,
      children,
      classNameIcon,
    } = this.props;
    return (
      <div
        onClick={toggleNavigation}
        className={cx(s.burger, className, { [s.cross]: isNavigationOpen })}
        style={{ cursor: 'pointer' }}
      >
        <svg viewBox="0 0 300 300" className={classNameIcon}>
          <g transform="translate(-330, -180)">
            <path
              d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
              className={s.top}
            />
            <path d="M300,320 L540,320" className={s.middle} />
            <path
              d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
              className={s.bottom}
              transform="translate(480, 320) scale(1, -1) translate(-480, -318) "
            />
          </g>
        </svg>
        {children}
      </div>
    );
  }
}

export default injectIntl(withStyles(s)(ToggleNavigation));
