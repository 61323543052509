import PropTypes from 'prop-types';
import React from 'react';
import {CSSTransition} from 'react-transition-group';
import Portal from './PortalCompat';

const KEYCODES = {
  ESCAPE: 27,
};

class PortalWithState extends React.Component {
  constructor(props) {
    super(props);
    this.portalNode = null;
    this.state = { active: !!props.defaultOpen };
    this.openPortal = this.openPortal.bind(this);
    this.closePortal = this.closePortal.bind(this);
    this.wrapWithPortal = this.wrapWithPortal.bind(this);
    this.handleOutsideMouseClick = this.handleOutsideMouseClick.bind(this);
    this.handleKeydown = this.handleKeydown.bind(this);
  }

  componentDidMount() {
    if (this.props.closeOnEsc) {
      document.addEventListener('keydown', this.handleKeydown);
    }
    if (this.props.closeOnOutsideClick) {
      document.addEventListener('click', this.handleOutsideMouseClick);
    }

    if (!!this.props.defaultOpen && this.props.onOpen) {
      this.props.onOpen();
    }
  }

  componentWillUnmount() {
    if (this.props.closeOnEsc) {
      document.removeEventListener('keydown', this.handleKeydown);
    }
    if (this.props.closeOnOutsideClick) {
      document.removeEventListener('click', this.handleOutsideMouseClick);
    }
  }

  openPortal(e) {
    if (this.state.active) {
      return;
    }
    if (e && e.nativeEvent) {
      e.nativeEvent.stopImmediatePropagation();
    }

    this.setState({ active: true }, this.props.onOpen);
  }

  closePortal() {
    if (!this.state.active) {
      return;
    }
    this.setState({ active: false });
  }

  handleEnter() {}

  handleExit() {}

  handleExited() {
    this.props.onClose();
  }

  wrapWithPortal(children) {
    // if (!this.state.active) {
    //  return null;
    // }
    return (
      <CSSTransition
        appear
        in={this.state.active}
        onEntered={this.handleEnter.bind(this)}
        onExit={this.handleExit.bind(this)}
        onExited={this.handleExited.bind(this)}
        timeout={{ enter: 1000, exit: 300 }}
        unmountOnExit
        classNames="modal-status"
      >
        <Portal
          node={this.props.node}
          key="react-portal"
          className={this.props.className}
          ref={portalNode => (this.portalNode = portalNode)}
        >
          {children}
        </Portal>
      </CSSTransition>
    );
  }

  handleOutsideMouseClick(e) {
    if (!this.state.active) {
      return;
    }
    const root = this.portalNode.props.node || this.portalNode.defaultNode;
    if (!root || root.contains(e.target) || (e.button && e.button !== 0)) {
      return;
    }
    this.closePortal();
  }

  handleKeydown(e) {
    if (e.keyCode === KEYCODES.ESCAPE && this.state.active) {
      this.closePortal();
    }
  }

  render() {
    return this.props.children({
      openPortal: this.openPortal,
      closePortal: this.closePortal,
      portal: this.wrapWithPortal,
      isOpen: this.state.active,
    });
  }
}

PortalWithState.propTypes = {
  children: PropTypes.func.isRequired,
  defaultOpen: PropTypes.bool,
  node: PropTypes.any,
  openByClickOn: PropTypes.element,
  closeOnEsc: PropTypes.bool,
  closeOnOutsideClick: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

PortalWithState.defaultProps = {
  onOpen: () => {},
  onClose: () => {},
};

export default PortalWithState;
