import React from 'react';
import cx from 'classnames';
import _ from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import autobind from 'autobind-decorator';
import parseISO from 'date-fns/parseISO';
import isAfter from 'date-fns/isAfter';
import { startAuthentication } from '../../../actions/auth.js';
import MenuItem from '../MenuItem';
import { trackAction, trackClick } from '../../../actions/analytics';
import BonusMeter from '../BonusMeter';
import { startRewardBrowser } from '../../../actions/reward.js';
import { startDeposit } from '../../../actions/deposit';
import DepositButton from './DepositButton';
import blog2 from './promotions.svg';
import rewardSidebar from './rewardSidebar.svg';
import plus from '../../../../public/img/icons/plus-circle.svg';
import bonus from './bonus.svg';
import user from './user.svg';
import dc from './dc.svg';
import lobby from './play.svg';
import liveMenu from './liveMenu.svg';
import s from '../SideBar.scss';
import messages from './side-bar-menu-authorized';
import { find } from '../../../core/cockpit';
import { setGameCategory } from '../../../actions/gamebrowser';
import { openModal } from '../../../actions/modal';
import Link from '../../Link';
import limitedTimePromotion from '../../../routes/promotion/promotion-detail.routes.json';
import limitedTimePromotions from '../../../routes/promotion/promotion-overview.routes.json';

@connect(
  (state) => ({
    isNavigationOpen: state.navigation.isNavigationOpen,
    isGameBrowserActive: state.navigation.isGameBrowserActive,
    browser: state.browser,
    activeRouteName: state.router.activeRoute.name,
    lobbyType: state.router.activeRoute.params.lobbyType,
    CountryCode: state.user.CountryCode,
    selectedSubCategory: state.gamebrowser.selectedSubCategory,
  }),
  (dispatch) => ({
    setGameCategory: () => dispatch(setGameCategory('', '')),
    trackClick: (action, params) => dispatch(trackClick(action, params)),
    startAuthentication: () => {
      dispatch(startAuthentication());
      dispatch(
        trackClick('Login', { component: 'Sidebar', variation: 'Default' }),
      );
    },
    startDeposit: () => {
      dispatch(startDeposit());
      dispatch(
        trackAction('Start Deposit', {
          variation: 'Default',
          component: 'SideBar/MenuAuthorized',
        }),
      );
    },
    startRewardBrowser: () => dispatch(startRewardBrowser()),
    goToAccount: () => {
      dispatch(openModal('ACCOUNT_MODAL'));
      dispatch(
        trackAction('Start Account', {
          variation: 'Default',
          component: 'Sidebar/MenuAuthorized',
        }),
      );
    },
  }),
)
@injectIntl
class SideBarMenuAuthorized extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleEnter = this.handleEnter.bind(this);
    this.handleExit = this.handleExit.bind(this);
    this.trackRegister = this.trackRegister.bind(this);
    this.state = { hide: false, counter: null };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeRouteName === 'promotion') {
      this.fetchData();
    }
  }

  trackRegister() {
    this.props.trackClick('Register', {
      component: 'Sidebar',
      variation: 'Default',
    });
  }
  @autobind
  fetchData() {
    const { intl } = this.props;
    let counter = 0;
    find('promotions', { active: 1, activeAspire: 1 }, 300, 0, intl.locale, 0, {
      weight: -1,
    }).then((result) => {
      const lastVisit = localStorage.getItem('LAST_VISIT_BLOG');

      console.log('lastVisit', lastVisit);
      if (!lastVisit) {
        console.log('default');
        this.setState({ counter: result.entries.length });
      } else {
        console.log('calc');
        result.entries.map((promo) => {
          console.log('startDate', parseISO(promo.startdate));
          console.log('lastVisit', lastVisit);
          console.log(
            'isAfter',
            isAfter(
              Date.parse(parseISO(promo.startdate)),
              Date.parse(lastVisit),
            ),
          );

          if (
            isAfter(
              Date.parse(parseISO(promo.startdate)),
              Date.parse(lastVisit),
            )
          ) {
            console.log('counter');
            counter++;
          }
        });
        this.setState({ counter });
      }
    });
  }

  @autobind
  handleEnter() {
    this.setState({ hide: true });
  }

  @autobind
  handleExit() {
    this.setState({ hide: false });
  }

  render() {
    const {
      isNavigationOpen,
      isGameBrowserActive,
      hasActiveBonuses,
      intl,
      startRewardBrowser,
      startDeposit,
      activeRouteName,
      lobbyType,
      CountryCode,
      selectedSubCategory,
      goToAccount,
      isAuthenticated,
      startAuthentication,
    } = this.props;

    const { counter } = this.state;

    return (
      <div className="w-full">
        <div
          ref={(el) => {
            this.menuContainer = el;
          }}
          className="mt-3"
        >
          {isAuthenticated && (
            <div className="px-2.5">
              <DepositButton />
            </div>
          )}

          {!isAuthenticated && (
            <div className="px-2.5 pt-8 space-y-3 max-w-[200px] mx-auto w-full">
              <Link
                className="btn btn--primary  w-full btn--lg"
                to="register"
                data-id="register"
              >
                {intl.formatMessage(messages.signup)}
              </Link>

              <Link
                to="auth"
                transition={false}
                className="btn btn--white-outline w-full btn--lg"
                onClick={() => {
                  startAuthentication();
                  this.trackRegister();
                }}
                data-id="login"
              >
                {intl.formatMessage(messages.login)}
              </Link>
            </div>
          )}
          <div className="space-y-2 mt-8">
            {isAuthenticated && (
              <MenuItem
                onClick={goToAccount}
                icon={user}
                className={cx({
                  'bg-white/40': activeRouteName === 'account',
                })}
              >
                {intl.formatMessage(messages.accountSettings)}
              </MenuItem>
            )}
            <MenuItem
              to="home"
              title=""
              icon={lobby}
              targetHash="gameBrowser"
              className={cx({
                'bg-white/40':
                  isGameBrowserActive &&
                  lobbyType === 'general' &&
                  selectedSubCategory !== 'game-shows',
              })}
            >
              {intl.formatMessage(messages.casino)}
            </MenuItem>
            {CountryCode !== 'DE' && (
              <MenuItem
                to="lobby"
                params={{ lobby: 'lobby', category: 'game-shows' }}
                title=""
                icon={liveMenu}
                className={cx({
                  'bg-white/40':
                    isGameBrowserActive && selectedSubCategory === 'game-shows',
                })}
              >
                {intl.formatMessage(messages.live)}
              </MenuItem>
            )}

            {CountryCode !== 'DE' && (
              <MenuItem
                to="dailyChallenge"
                title=""
                icon={dc}
                className={cx({
                  'bg-white/40': activeRouteName === 'dailyChallenge',
                })}
              >
                {intl.formatMessage(messages.dailyChallengesTitle)}
              </MenuItem>
            )}
            {CountryCode !== 'DE' && (
              <MenuItem
                to="promotion"
                title="promotion"
                icon={blog2}
                counter={counter}
                className={cx({
                  'bg-white/40':
                    activeRouteName === 'promotion' ||
                    activeRouteName === 'promotionDetail',
                })}
              >
                {intl.formatMessage(messages.blog)}
              </MenuItem>
            )}
            <MenuItem
              to="limitedTimePromotions"
              title="limitedTimePromotions"
              icon={blog2}
              counter={counter}
              className={cx({
                'bg-white/40':
                  activeRouteName === 'limitedTimePromotions' ||
                  activeRouteName === 'limitedTimePromotion',
              })}
            >
              ASG promotions
            </MenuItem>
            {isAuthenticated && CountryCode !== 'DE' && (
              <MenuItem onClick={startRewardBrowser} icon={rewardSidebar}>
                {intl.formatMessage(messages.rewards)}
              </MenuItem>
            )}
          </div>
        </div>

        <div>
          {/* {isNavigationOpen && <SideBarMenuBalance />} */}
          {isNavigationOpen && (
            <a
              onClick={startDeposit}
              className={cx(
                s.startDeposit,
                'attentionButton',
                'actionButton',
                s.depositButton,
              )}
            >
              <span>{intl.formatMessage(messages.depositNow)}</span>

              <span className={s.span3}>
                <svg width="25" height="25">
                  <use xlinkHref={`${plus}#root`} />
                </svg>
              </span>
            </a>
          )}

          {/* <LogOut className={s.bottomAction}>
            <span>{intl.formatMessage(messages.logout)}</span>
            <svg width="20" height="20">
              <use xlinkHref={`${logout}#root`} />
            </svg>
          </LogOut> */}
        </div>
      </div>
    );
  }
}

export default withStyles(s)(SideBarMenuAuthorized);
