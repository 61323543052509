import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import _ from 'lodash';
import React from 'react';
import {defineMessages, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {closeModal} from '../../../actions/modal';
import s from './GameBrowserSearchModal.scss';
import GameItem from '../GameItem';
import NavigatorIcon from '../../NavigatorIcon';
import search from '../../../../public/img/icons/search.svg?external';
import {trackAction} from '../../../actions/analytics';
import {getSubCategoryMem} from '../../../data/model/game-category';

const messages = defineMessages({
  gameOnTypeToSearch: {
    id: 'placeholder.typeToSearch',
    defaultMessage: 'Search games...',
    description: 'The placeholder for the searchfield in the game screen',
  },
  textSearch: {
    id: 'text.search',
    defaultMessage: 'Search',
    description: 'The category name for te gambrowser navigator',
  },
  results: {
    id: 'text.results',
    defaultMessage: 'Results',
    description: 'The category name for te gambrowser navigator',
  },
  labelPopulair: {
    id: 'populairGames',
    defaultMessage: 'Popular games',
    description: "the label on a game if it's new",
  },
});

@connect(
  (state) => ({
    gamebrowser: state.gamebrowser,
    isMobile: state.browser.lessThan.large,
    licenseType: state.license.type,
    isAuthenticated: state.auth.isAuthenticated,
    lobbyType: state.router.activeRoute.params.lobbyType,
    IpCountry: state.user.IpCountry,
  }),
  (dispatch) => ({
    closeModal: () => dispatch(closeModal('GAME_FILTER_MODAL')),
    trackAction: (event, data) => dispatch(trackAction(event, data)),
  }),
)
@withStyles(s)
@injectIntl
class GameBrowserSearchModal extends React.Component {
  constructor(props) {
    super(props);
    this.search = _.debounce(this.search, 500);
    this.search = this.search.bind(this);
    this.state = { games: [], query: '' };
  }

  componentDidMount() {
    const { setTitle, intl, IpCountry, isMobile } = this.props;

    // Set the title of he modal
    setTitle(intl.formatMessage(messages.textSearch));

    const getGames = getSubCategoryMem(
      'CASINO',
      'VIDEOSLOTS',
      isMobile,
      IpCountry,
    );

    getGames.then((result) => {
      // retrieve the first 10 slots games
      this.setState({ games: result?.instantGamesOrder?.slice?.(0, 10) ?? [] });
    });
  }

  search(query) {
    const { lobbyType, trackAction, IpCountry, isMobile } = this.props;

    const getGames = getSubCategoryMem(
      'CASINO',
      'VIDEOSLOTS',
      isMobile,
      IpCountry,
    );

    getGames.then((result) => {
      const searchResultSearch =
        _.size(query) >= 2
          ? _.filter(
              result.instantGamesOrder,
              _.method('name.match', new RegExp(query, 'i')),
            )
          : result?.slice?.(0, 10) ?? [];
      const searchResultVendor = _.filter(
        result,
        _.method('subVendor.match', new RegExp(query, 'i')),
      );
      let searchResult = _.concat(searchResultSearch, searchResultVendor);
      searchResult =
        lobbyType === 'live'
          ? _.filter(searchResult, (o) => !_.isUndefined(o.tableID))
          : searchResult;

      this.setState({ games: searchResult, query });
    });

    // Only track if takes more then 5 seconds
    setTimeout(() => {
      trackAction('Search for game', {
        component: 'GameBrowser/GameBrowserSearchModal',
        variation: 'Default',
        query,
      });
    }, 5000);
  }

  render() {
    const { intl } = this.props;
    const { games, query } = this.state;
    return (
      <>
        <div className={cx('sectionContainer', s.sectionSearchContainer)}>
          <div className="sectionGroup transparent">
            <div className="section content fullWidth">
              <div className={cx('formContainer', s.formContainer)}>
                <svg width="20" height="20" className={s.icon}>
                  <image xlinkHref={search} width="20" height="20" />
                </svg>

                <input
                  name="query"
                  id="query"
                  component="input"
                  type="text"
                  autoFocus
                  placeholder={intl.formatMessage(messages.gameOnTypeToSearch)}
                  onKeyUp={(e) => {
                    this.search(e.target.value);
                  }}
                  className={cx('input', s.searchInput)}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className={cx('sectionContainer', 'fullWidth', s.sectionContainer)}
        >
          <div className="sectionGroup transparent">
            <div>
              {_.size(query) >= 2 ? (
                <h3 className={s.header}>
                  {intl.formatMessage(messages.results)} ({_.size(games)})
                </h3>
              ) : (
                <h3 className={s.header}>
                  {intl.formatMessage(messages.labelPopulair)}
                </h3>
              )}
              {games.map((game) => (
                <GameItem className={s.gameItem} game={game} key={game.slug}>
                  <picture>
                    <img
                      src={`${game.images.default}`}
                      className={s.thumbnail}
                    />
                  </picture>
                  <span>{game.name}</span>
                  <NavigatorIcon className={s.navigationRight} />
                </GameItem>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default GameBrowserSearchModal;
