import _ from 'lodash';
import React from 'react';
import {connect} from 'react-redux';
import {closeNavigation} from '../../actions/navigation';
import inobounce from '../../core/inobounce';
import RootModal from './RootModal';

@connect(
  state => ({
    modal: state.modal,
    isIos: _.get(state, 'device.apple.device'),
  }),
  dispatch => {
    return {
      closeNavigation: () => dispatch(closeNavigation()),
    };
  },
)
class Modal extends React.PureComponent {
  componentDidUpdate() {
    const { modal, isIos, closeNavigation } = this.props;

    // Sad we need a tool like this to make scrolling overflow work on mobile
    // But eventually it's the result that matters.
    if (isIos) {
      if (_.size(modal) === 0) {
        inobounce.disable();
      } else if (!inobounce.isEnabled()) {
        inobounce.enable();
      }
    }
    closeNavigation();
  }

  componentWillUnmount() {
    const { isIos } = this.props;
    if (isIos) {
      inobounce.disable();
    }
  }

  render() {
    const { modal } = this.props;
    const layoutPage = (
      <>
        {modal.map(function(item, key) {
          return <RootModal modal={item} key={item.modalName} />;
        })}
      </>
    );
    return layoutPage;
  }
}

export default Modal;
