// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".GameBrowserSearchModal-header-139sP{font-family:var(--font-family-variant);font-size:20px;color:var(--color-darkblue);margin-bottom:10px;padding:0;margin-left:10px;margin-top:0}.GameBrowserSearchModal-gameItem-3bO1B{display:flex;align-items:center;flex-direction:rows;justify-content:flex-start;border-top:1px solid #a7f2f8;padding:10px 0;box-sizing:border-box;text-decoration:none}.GameBrowserSearchModal-gameItem-3bO1B span{flex:1 1;color:var(--color-darkgreen);text-decoration:none}.GameBrowserSearchModal-gameItem-3bO1B .GameBrowserSearchModal-thumbnail-M_6hx{height:30px;width:30px;margin:0 10px;display:block;border-radius:5px;object-fit:contain}.GameBrowserSearchModal-navigationRight-2ZHDe{border-radius:50%;background-color:var(--color-darkgreen);padding:5px;height:20px;width:20px;display:flex;margin-right:10px;justify-content:center;flex-direction:column;align-items:center;flex-grow:0;flex-shrink:0;top:50%;transform:rotate(270deg)}.GameBrowserSearchModal-navigationRight-2ZHDe polyline{stroke:#fff!important}.GameBrowserSearchModal-navigationRight-2ZHDe svg{height:15px;width:15px}.GameBrowserSearchModal-formContainer-3P6-G{position:relative;width:100%}.GameBrowserSearchModal-icon-4_2__{position:absolute;margin:10px 20px}.GameBrowserSearchModal-searchInput-JiAxv{height:2.5rem;font-size:1rem;padding:0 0 0 3rem;margin-bottom:0;width:100%;height:45px;border-radius:50px}.GameBrowserSearchModal-sectionContainer-2kj_I{padding-top:0}.GameBrowserSearchModal-sectionSearchContainer-2qIRX{flex-grow:0}.game-name{display:none!important}", ""]);
// Exports
exports.locals = {
	"header": "GameBrowserSearchModal-header-139sP",
	"gameItem": "GameBrowserSearchModal-gameItem-3bO1B",
	"thumbnail": "GameBrowserSearchModal-thumbnail-M_6hx",
	"navigationRight": "GameBrowserSearchModal-navigationRight-2ZHDe",
	"formContainer": "GameBrowserSearchModal-formContainer-3P6-G",
	"icon": "GameBrowserSearchModal-icon-4_2__",
	"searchInput": "GameBrowserSearchModal-searchInput-JiAxv",
	"sectionContainer": "GameBrowserSearchModal-sectionContainer-2kj_I",
	"sectionSearchContainer": "GameBrowserSearchModal-sectionSearchContainer-2qIRX"
};
module.exports = exports;
