import React from 'react';
import cn from 'classnames';

import {DEFAULT_PROP_VALUES, MAX_STROKE_DASHARRAY_100PX} from './progress-bar.constants';

class ProgressBar extends React.Component {
  constructor(props) {
    super(props);
    const {
      start = DEFAULT_PROP_VALUES.start,
      radius = DEFAULT_PROP_VALUES.radius,
      speed = DEFAULT_PROP_VALUES.speed,
      width = DEFAULT_PROP_VALUES.width,
    } = this.props;

    this.shapes = {
      circle: {
        selector: 'stroke-dashoffset',
        method: 'getCircle',
        maxValue: radius * MAX_STROKE_DASHARRAY_100PX / 100,
      },
      horizontal: {
        selector: 'width',
        method: 'getHorizontal',
        maxValue: width.toString().replace(/%/, ''),
      },
    };

    this.getCircle = this.getCircle.bind(this);
    this.getHorizontal = this.getHorizontal.bind(this);
    this.getShapeMethodArguments = this.getShapeMethodArguments.bind(this);

    this.state = {
      animation: {
        strokeDashoffset: this.getRealValue(start),
        width: this.getRealValue(start),
        transition: `stroke-dashoffset ${speed}s ease-out`,
      },
    };
  }

  componentDidMount() {
    const { timeout = DEFAULT_PROP_VALUES.timeout } = this.props;

    setTimeout(() => {
      const { end = DEFAULT_PROP_VALUES.end } = this.props;
      this.updateProgress(end);
    }, timeout * 1000);
  }

  componentWillReceiveProps(nextprops) {
    const { end = DEFAULT_PROP_VALUES.end } = nextprops;
    this.updateProgress(end);
  }

  getRealValue(percents) {
    const { shape } = this.props;
    const realValue = ((percents * this.shapes[shape].maxValue) / 100);
    return shape === 'circle' ? this.shapes[shape].maxValue - realValue : realValue;
  }

  getCircle({
    color, fill, strokeDasharray, animation,
  }) {
    const {
      radius = DEFAULT_PROP_VALUES.radius,
      strokeWidth = DEFAULT_PROP_VALUES.strokeWidth,
    } = this.props;
    const circlePos = parseInt(radius, 10) + parseInt(strokeWidth, 10);

    return (
      <circle
        r={radius}
        fill={fill}
        cx={circlePos}
        cy={circlePos}
        strokeDasharray={strokeDasharray}
        transform={`rotate(-90, ${circlePos}, ${circlePos})`}
        style={animation}
        strokeWidth={strokeWidth}
        stroke={color}
      />
    );
  }

  getHorizontal({ color, width, animation }) {
    const {
      height = DEFAULT_PROP_VALUES.height,
      borderRadius = DEFAULT_PROP_VALUES.borderRadius,
    } = this.props;

    return (
      <rect
        fill={color}
        style={animation}
        width={width}
        height={height}
        rx={borderRadius}
        ry={borderRadius}
      />
    );
  }

  getShapeMethodArguments() {
    const {
      color = DEFAULT_PROP_VALUES.color,
      colorBackground = DEFAULT_PROP_VALUES.colorBackground,
      fill = DEFAULT_PROP_VALUES.fill,
      shape,
      start = DEFAULT_PROP_VALUES.start,
      width = DEFAULT_PROP_VALUES.width,
    } = this.props;
    const { animation } = this.state;
    const args = [];

    switch (shape) {
      case 'circle':
        args.push({
          color: colorBackground || 'transparent',
          fill,
          strokeDasharray: 0,
        });
        args.push({
          color,
          fill: 'transparent',
          strokeDasharray: this.shapes[shape].maxValue,
          animation,
        });
        break;
      case 'horizontal':
        args.push({
          color: colorBackground || 'transparent',
          width,
        });
        args.push({
          color,
          width: start,
          animation,
        });
        break;
      default:
        break;
    }

    return args;
  }

  updateProgress(value) {
    const {
      speed = DEFAULT_PROP_VALUES.speed,
      shape,
      width = DEFAULT_PROP_VALUES.width,
    } = this.props;

    const suffix = width.toString().includes('%') ? '%' : '';

    this.setState({
      animation: {
        strokeDashoffset: this.getRealValue(value),
        width: this.getRealValue(value) + suffix,
        transition: `${this.shapes[shape].selector} ${speed}s ease-out`,
      },
    });
  }

  render() {
    const {
      radius = DEFAULT_PROP_VALUES.radius,
      strokeWidth = DEFAULT_PROP_VALUES.strokeWidth,
      x = DEFAULT_PROP_VALUES.x,
      y = DEFAULT_PROP_VALUES.y,
      width = DEFAULT_PROP_VALUES.width,
      height = DEFAULT_PROP_VALUES.height,
      shape,
      className,
    } = this.props;
    const svgWidth = shape === 'circle' ? (parseInt(radius, 10) * 2) + (parseInt(strokeWidth, 10) * 2) : width;
    const svgHeight = shape === 'circle' ? (parseInt(radius, 10) * 2) + (parseInt(strokeWidth, 10) * 2) : height;

    return (
      <svg
        x={parseInt(x, 10)}
        y={parseInt(y, 10)}
        className={cn(className)}
        width={svgWidth}
        height={svgHeight}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        {this[this.shapes[shape].method](this.getShapeMethodArguments()[0])}
        {this[this.shapes[shape].method](this.getShapeMethodArguments()[1])}
      </svg>
    );
  }
}

export default ProgressBar;
