import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import _ from 'lodash';
import { defineMessages, injectIntl } from 'react-intl';
import { Transition } from '@headlessui/react';
import s from './Navigation.scss';
import { Portal } from '../Portal';
import inobounce from '../../core/inobounce';
import SideBarMenuPublic from '../SideBar/SideBarMenu/SideBarMenuPublic';
import Link from '../Link';
import ActionHolder from '../SideBar/ActionHolder/ActionHolder';
import logo from './playfrank-logo-h.svg';

const MenuAuthorized = lazy(() =>
  import(/* webpackChunkName: 'MenuAuthorized' */ './MenuAuthorized'),
);
const messages = defineMessages({
  altLogo: {
    id: 'alt.sidebar.logo',
    defaultMessage: 'PlayFrank Online Casino - The Best in Online Slots',
    description: 'Alt text',
  },
});
@connect((state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isNavigationOpen: state.navigation.isNavigationOpen,
  CountryCode: state.user.CountryCode,
  IpCountry: state.user.IpCountry,
  isIos: _.get(state, 'device.apple.device'),
}))
@injectIntl
class Navigation extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { items: [], isLoading: true };
  }

  componentDidUpdate() {
    const { isNavigationOpen, isIos } = this.props;
    // Sad we need a tool like this to make scrolling overflow work on mobile
    // But eventually it's the result that matters.
    if (isIos) {
      if (!isNavigationOpen) {
        inobounce.disable();
      } else if (!inobounce.isEnabled()) {
        inobounce.enable();
      }
    }
  }

  componentWillUnmount() {
    const { isIos } = this.props;
    if (isIos) {
      inobounce.disable();
    }
  }

  render() {
    const { isAuthenticated, isNavigationOpen, intl, CountryCode, IpCountry } =
      this.props;
    return (
      // isNavigationOpen && (
      <Portal
        node={process.env.BROWSER && document.body}
        className={s.root}
        closeOnEsc
      >
        <Transition
          show={isNavigationOpen}
          className={cx('origin-left', s.root, {
            [s.isOpen]: isNavigationOpen,
          })}
          enter="transition duration-300 ease-out"
          enterFrom="transform -translate-x-20 opacity-0"
          enterTo="transform translate-x-0 opacity-100"
          leave="transition duration-300 ease-out"
          leaveFrom="transform translate-x-0 opacity-100"
          leaveTo="transform -translate-x-20 opacity-0"
        >
          <div className={s.navigationContainer}>
            <div className={s.logoContainer}>
              <Link to="home">
                <img
                  src={logo}
                  alt={intl.formatMessage(messages.altLogo)}
                  className="w-[220px] mx-auto "
                />
              </Link>
            </div>
            {isAuthenticated && IpCountry !== 'UK' && CountryCode !== 'UK' && (
              <div className={s.actionHolderContainer}>
                <Suspense fallback={<div />}>
                  <ActionHolder />
                </Suspense>
              </div>
            )}
            {!isAuthenticated ? (
              <SideBarMenuPublic />
            ) : (
              <Suspense fallback={<div />}>
                <MenuAuthorized />
              </Suspense>
            )}
          </div>
        </Transition>
      </Portal>
      // )
    );
  }
}

export default withStyles(s)(Navigation);
