import { boundMethod } from 'autobind-decorator';
import withStyles from 'isomorphic-style-loader/withStyles';
import React from 'react';
import Headroom from 'react-headroom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { startAuthentication } from '../../actions/auth';
import { bottomMenuStatus } from '../../actions/navigation';
import s from './BottomBar.scss';
import BottomBarMenuPublic from './BottomBarMenuPublic.js';
import BottomBarMenuAuthorized from './BottomBarMenuAuthorized.js';

@connect(
  (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    activeRouteName: state.router.activeRoute.name,
    isNavigationOpen: state.navigation.isNavigationOpen,
  }),
  (dispatch) => ({
    startAuthentication: () => dispatch(startAuthentication()),
    bottomMenuStatus: (status) => dispatch(bottomMenuStatus(status)),
  }),
)
@injectIntl
@withStyles(s)
class BottomBar extends React.Component {
  @boundMethod
  onPin() {
    this.props.bottomMenuStatus('pinned');
  }

  @boundMethod
  onUnpin() {
    this.props.bottomMenuStatus('unpinned');
  }

  @boundMethod
  onUnfix() {
    this.props.bottomMenuStatus('unfixed');
  }

  render() {
    const { isAuthenticated, activeRouteName, isNavigationOpen } = this.props;

    if (activeRouteName == 'xperience') {
      return null;
    }
    return (
      <Headroom
        disableInlineStyles
        onPin={this.onPin}
        onUnpin={this.onUnpin}
        onUnfix={this.onUnfix}
      >
        <nav
          className={`lg:hidden h-14 w-full px-3 ${
            isAuthenticated
              ? 'grid grid-cols-3 gap-2'
              : 'flex justify-between items-center'
          } ${
            !isNavigationOpen
              ? 'bg-gradient-to-t from-darkblue to-[#04C9C4]'
              : ''
          }`}
          role="navigation"
        >
          {!isAuthenticated ? (
            <BottomBarMenuPublic />
          ) : (
            <BottomBarMenuAuthorized />
          )}
          {/* <BottomBarMenuPublic /> */}
        </nav>
      </Headroom>
    );
  }
}

export default BottomBar;
