import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import React from 'react';
import {injectIntl} from 'react-intl';
import backIcon from '../../../public/img/icons/back.svg';
import {PortalWithState} from '../Portal';
import s from './Navigator.scss';

class Navigator extends React.PureComponent {
  render() {
    const {
      backAction,
      backActionClassname,
      title,
      nextAction,
      nextActionClassname,
      closeAction,
      className,
    } = this.props;

    return (
      <PortalWithState
        defaultOpen
        node={document.body}
        className={s.root}
        closeOnEsc
      >
        {({ portal }) =>
          portal(
            <div className={cx(s.root, className)}>
              <div className={s.previousActionContainer}>
                {backAction && (
                  <button
                    onClick={backAction}
                    className={cx(s.backAction, backActionClassname)}
                  >
                    <svg width="20" height="15">
                      <use xlinkHref={`${backIcon}#root`} />
                    </svg>
                  </button>
                )}
              </div>

              {title && <div className={s.title}>{title}</div>}

              {nextAction && (
                <button
                  onClick={nextAction}
                  className={cx(s.nextAction, nextActionClassname)}
                >
                  <svg width="20" height="15">
                    <use xlinkHref={`${backIcon}#root`} />
                  </svg>
                </button>
              )}

              <div className={s.closeActionContainer}>
                {closeAction && (
                  <button
                    onClick={closeAction}
                    className={cx('closeButton', s.closeButton)}
                  />
                )}
              </div>
            </div>,
          )
        }
      </PortalWithState>
    );
  }
}

export default injectIntl(withStyles(s)(Navigator));
