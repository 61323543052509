import React from 'react';
import {defineMessages, injectIntl} from 'react-intl';

const messages = defineMessages({
  altBlog: {
    id: 'alt.social.blog',
    defaultMessage: 'Get latest news by visiting our blog',
    description: 'Alt text',
  },
  altFacebook: {
    id: 'alt.social.facebook',
    defaultMessage: 'Visit our facebook',
    description: 'Alt text',
  },
  altInstagram: {
    id: 'alt.social.instagram',
    defaultMessage: 'Visit our instagram',
    description: 'Alt text',
  },
});

@injectIntl
class Seo extends React.Component {
  getBlogUrl() {
    const { intl } = this.props;

    switch (intl.locale) {
      // case 'no':
      // case 'sv':
      //   return `/${intl.locale}/blogg/`;

      case 'de':
        return `/en/blog/`;

      default:
        return `/${intl.locale}/blog/`;
    }
  }

  render() {
    const { intl, license } = this.props;

    return (
      <div className="">
        <div className="flex space-x-4 items-center">
          <a href={this.getBlogUrl()} target="_blank" rel="noopener noreferrer">
            <img
              src="/img/logos/social/blog.svg"
              alt={intl.formatMessage(messages.altBlog)}
              height="30"
              width="30"
              loading="lazy"
            />
          </a>
          <a
            href="https://www.facebook.com/PlayFrankCasino/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/img/logos/social/facebook.svg"
              alt={intl.formatMessage(messages.altFacebook)}
              height="30"
              width="30"
              loading="lazy"
            />
          </a>

          <a
            href="https://www.instagram.com/playfrankcasino/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/img/logos/social/instagram.svg"
              alt={intl.formatMessage(messages.altInstagram)}
              height="30"
              width="30"
              loading="lazy"
            />
          </a>
        </div>
      </div>
    );
  }
}

export default Seo;

/*
<a
            href={this.getBlogUrl()}
            target="_blank"
            rel="noopener"
          >
            <img src="/img/logos/social/blog.svg" />
          </a>
<a
            href={this.getBlogUrl()}
            target="_blank"
            rel="noopener"
          >
            <img src="/img/logos/social/blog.svg" />
          </a>

<a href="https://twitter.com" target="_blank" rel="noopener">
            <img src="/img/logos/social/twitter.svg" />
          </a>
<div className={s.gdpr}>
          <img src="/img/logos/licensing/gdpr-compliant.svg" />
        </div>
 {intl.messages[(license.isUK) ? 'footer.license.uk' : 'footer.license.general']}
 */
