import { defineMessages } from 'react-intl';

export default defineMessages({
  signup: {
    id: 'signup',
    defaultMessage: 'Signup',
    description: 'General word',
  },
  login: {
    id: 'login',
    defaultMessage: 'Login',
    description: 'General word',
  },
  deposit: {
    id: 'deposit',
    defaultMessage: 'Deposit',
    description: 'General deposit word',
  },
  withdraws: {
    id: 'withdraws',
    defaultMessage: 'Withdraws',
    description: 'General word',
  },
  bonus: {
    id: 'bonus',
    defaultMessage: 'Bonus',
    description: 'General word',
  },
  rewards: {
    id: 'rewards',
    defaultMessage: 'Rewards',
    description: 'General word',
  },
  blog: {
    id: 'blog',
    defaultMessage: 'Blog',
    description: 'General word',
  },
  accountSettings: {
    id: 'accountSettings',
    defaultMessage: 'My account',
    description: 'General word',
  },
  logout: {
    id: 'logout',
    defaultMessage: 'Logoout',
    description: 'General word',
  },
  games: {
    id: 'games',
    defaultMessage: 'Games',
    description: 'General word',
  },
  casino: {
    id: 'casino',
    description: 'Casino',
    defaultMessage: 'Casino',
  },
  depositNow: {
    id: 'depositNow',
    defaultMessage: 'Deposit now',
    description: 'General word',
  },
  dailyChallengesTitle: {
    id: 'dailyChallengesTitle',
    defaultMessage: 'Daily Challenges',
    description: 'Name of the xp missions',
  },
  slugLiveLobby: {
    id: 'slug.liveLobby',
    defaultMessage: 'live-lobby',
    description: 'The SEO description for the home page',
  },
  live: {
    id: 'text.live',
    defaultMessage: 'Game Shows',
    description: 'The category name for te gambrowser navigator',
  },
});
