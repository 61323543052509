import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import fetch from 'node-fetch';
import Link from '../Link';
import getSmartFooter from './get-smart-footer.graphql';

const messages = defineMessages({
  playResponsibly: {
    id: 'playResponsibly',
    defaultMessage: 'PLAY RESPONSIBLY',
    description: 'About us',
  },
  paymentOption: {
    id: 'paymentOption',
    defaultMessage: 'PAYMENT OPTION',
    description: 'About us',
  },
});
const Licensing = () => {
  const intl = useIntl();
  const IpCountry = useSelector((state) => state.user.IpCountry);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const CountryCode = useSelector((state) => state.user.CountryCode);
  const alpha3 = useSelector((state) => state.intl.alpha3);
  const feGuideline = useSelector((state) => state.license.feGuideline);
  const [smartFooter, setSmartFooter] = useState([]);

  // const { loading, error, data } = useQuery(getFooterIcons, {
  //   variables: {
  //     lng: alpha3,
  //     brand: 'playfrank',
  //     country: (isAuthenticated) ? CountryCode : IpCountry,
  //     branddomain: 'playfrank.com',
  //   },
  // });

  useEffect(() => {
    let reg = 'mga';
    let country = isAuthenticated ? CountryCode : IpCountry;
    if (country.toLowerCase() === 'uk' || country.toLowerCase() === 'gb') {
      reg = 'gb';
      country = 'GB';
    }

    fetch(
      `https://api.aspireglobal.com/api/v1/brands/279/smart-footer?host=playfrank.com&country=${country}&reg=${reg}`,
    ).then(async (response) => {
      const responseParams = await response.json();

      setSmartFooter({ smartFooter: responseParams ?? [] });
      return responseParams;
    });
  }, []);
  const { data: smartFooterr } = useQuery(getSmartFooter, {
    variables: { country: isAuthenticated ? CountryCode : IpCountry },
  });

  const onClick = (func) => {
    const F = new Function(func);
    return F();
  };

  // Possible values:
  // 1 - new window
  // 2 - new tab
  // 3 - same tab
  // NULL (default)
  const getClick = (e, item, type) => {
    e.preventDefault();
    switch (item?.iconLinkOpen) {
      case 1:
      case 2:
        if (type === 'underage') {
          return window.open(
            `https://info.playfrank.com/underage.aspx?popup=1&lng=${alpha3}`,
          );
        }
        window.open(item?.iconLink);
        break;
      case 3:
        window.location.href = item?.iconLink;
        break;
    }
  };
  return (
    <>
      <div className="text-base font-bold   text-white uppercase text-opacity-90   text-left ">
        {intl.formatMessage(messages.playResponsibly)}
      </div>
      <div className="flex flex-row flex-wrap items-center   mt-2 mb-0 gap-4">
        {/* {data?.licenseLogos?.map(item => ( */}
        {/*  <Link */}
        {/*    href={item.href} */}
        {/*    onClick={item.onclick ? () => onClick(item.onclick) : null} */}
        {/*    target="_blank" */}
        {/*  > */}
        {/*    <img */}
        {/*      src={`/img/footer/white/${item.slug}.svg`} */}
        {/*      className="mb-4 h-10 w-auto" */}
        {/*      alt="" */}
        {/*    /> */}
        {/*  </Link> */}
        {/* ))} */}

        {Object.keys(
          smartFooter?.smartFooter?.data?.regulationLinks || {},
        )?.map((item) => (
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href={
              smartFooter?.smartFooter?.data?.regulationLinks[item]?.iconLink
            }
            ariaLabel={item}
            key={item}
            onClick={(e) =>
              getClick(
                e,
                smartFooter?.smartFooter?.data?.regulationLinks[item],
                item,
              )
            }
          >
            <img
              src={`https://preprod.playfrank.com/cdn-cgi/image/format=auto,dpr=2,sharpen=1/${smartFooter?.smartFooter?.data?.regulationLinks[item].iconLogo}`}
              className={cx(' h-5 md:h-6 w-auto filter invert brightness-0')}
              alt=""
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = `https://preprod.playfrank.com/cdn-cgi/image/format=auto,dpr=2,sharpen=1/${smartFooter?.smartFooter?.data?.regulationLinks[item].iconLogo}`;
                // currentTarget.style.cssText = 'filter: brightness(0) grayscale(100%) invert(100%)'
              }}
              loading="lazy"
              height="24"
              width="100"
            />
          </Link>
        ))}
      </div>
      <div
        className={cx(
          'text-base font-bold   text-white text-opacity-90 uppercase mt-7',
        )}
      >
        {intl.formatMessage(messages.paymentOption)}
      </div>
      <div className="flex flex-row flex-wrap items-center  mt-2 mb-0 gap-4">
        {/* {data?.depositLogos?.map(item => ( */}
        {/*  <img */}
        {/*    src={`/img/footer/white/${item.slug}.svg`} */}
        {/*    className="mb-8 h-6 w-auto" */}
        {/*    alt="" */}
        {/*  /> */}
        {/* ))} */}
        {Object.keys(smartFooter?.smartFooter?.data?.paymentMethods || {})?.map(
          (item) => (
            <img
              key={item}
              src={`https://preprod.playfrank.com/cdn-cgi/image/format=auto,dpr=2,sharpen=1/${smartFooter?.smartFooter?.data?.paymentMethods[item].paymentLogo}`}
              className={cx('  h-5 md:h-6 w-auto filter invert brightness-0')}
              alt=""
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = `https://preprod.playfrank.com/cdn-cgi/image/format=auto,dpr=2,sharpen=1/${smartFooter?.smartFooter?.data?.paymentMethods[item].paymentLogo}`;
                // currentTarget.style.cssText = 'filter: brightness(0) grayscale(100%) invert(100%)'
              }}
              loading="lazy"
              height="24"
              width="100"
            />
          ),
        )}
      </div>
    </>
  );
};

export default Licensing;
