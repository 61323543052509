import format from 'date-fns/format';
import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import {injectIntl} from 'react-intl';
import s from './SideBarClock.scss';

@injectIntl
class Clock extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { time: '' };
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    this.setState({
      time: format(new Date(), 'HH:mm'),
    });
  }

  render() {
    return <span className={s.root}>{this.state.time}</span>;
  }
}

export default withStyles(s)(Clock);
