import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import s from './Footer.scss';
import { Transition } from '@headlessui/react';
import LanguageSwitcher from './LanguageSwitcher';
import Navigation from './FooterNavigation';
import License from './License';
import Seo from './Seo';
import Social from './Social';
import Affiliates from './Affiliates';
import Licensing from './Licensing';
import SsrCompatibleSuspense from '../SsrCompatibleSuspense';
import mga from './mga.png';
import SeoCta from '../../routes/cockpit/casino/SeoCta';

function Footer({ navigation }) {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isMobile = useSelector((state) => state.device.isMobile);
  const hreflang = useSelector((state) => state.router.activeRoute.hreflang);
  const bottomMenuStatus = useSelector(
    (state) => state.navigation.bottomMenuStatus,
  );

  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    const scrolled = document.documentElement.scrollTop;
    if (!isAuthenticated) {
      if (scrolled > 100) {
        setIsVisible(true);
      } else if (scrolled <= 100) {
        setIsVisible(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [isAuthenticated]);

  return (
    <>
      <Transition
        appear
        unmount={false}
        show={isVisible && !isAuthenticated}
        className={`fixed ${
          bottomMenuStatus === 'pinned' ? 'bottom-14' : 'bottom-0'
        }  lg:bottom-0 transition-all lg:left-[180px] left-0 right-0 z-50 lg:w-[calc(100%-180px)] w-full`}
        enter="transition duration-100 ease-out"
        enterFrom="transform translate-y-5 opacity-0"
        enterTo="transform translate-y-0 opacity-100"
        leave="transition duration-100 ease-out"
        leaveFrom="transform translate-y-0 opacity-100"
        leaveTo="transform translate-y-5 opacity-0"
      >
        <SeoCta />
      </Transition>

      <footer className="relative ">
        <div className="container">
          {!isAuthenticated && (
            <SsrCompatibleSuspense fallback={<div />}>
              <Seo />
            </SsrCompatibleSuspense>
          )}
        </div>
        <div className="bg-blue py-10 pb-20">
          <div className="container">
            {navigation && <Navigation />}
            {/* <Logos /> */}
            <div className="grid md:grid-cols-2 md:gap-0 gap-5 ">
              <div className="">
                <SsrCompatibleSuspense fallback={<div />}>
                  <Licensing />
                </SsrCompatibleSuspense>
              </div>
              <Affiliates />
            </div>
            <div className=" ">
              <SsrCompatibleSuspense fallback={<div />}>
                <License />
              </SsrCompatibleSuspense>
            </div>
            <div className=" flex flex-wrap items-center justify-between">
              {/*<div className="">*/}
              {/*  <img src={mga} alt="mga" />*/}
              {/*</div>*/}
              <div>
                <Social />
              </div>
              <div className="lg:mt-0 mt-5 w-full lg:w-auto">
                <LanguageSwitcher hreflang={hreflang} />
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

Footer.defaultProps = {
  navigation: true,
};

export default Footer;
