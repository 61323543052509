import React from 'react';

class Navigator extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div {...this.props}>
        <svg viewBox="0 0 14 8">
          <polyline
            style={{
              fill: 'none',
              stroke: '#f60',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2px',
            }}
            points="1 1 7 7 13 1"
          />
        </svg>
      </div>
    );
  }
}

export default Navigator;
