export const MAX_STROKE_DASHARRAY_100PX = 630;

export const DEFAULT_PROP_VALUES = {
  start: 0,
  end: 100,
  strokeWidth: 5,
  radius: 100,
  borderRadius: 5,
  width: 200,
  height: 10,
  speed: 1,
  timeout: 1,
  color: '#FF9F1E',
  colorBackground: 'transparent',
  fill: 'transparent',
  x: 0,
  y: 0,
};
