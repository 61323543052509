const PROGRESS_BAR_CONFIGURATION = {
  shape: 'horizontal',
  height: 8,
  color: '#efe856',
  speed: 5,
  width: 75,
  colorBackground: '#6acef25c',
  x: 10,
  y: 5,
};

export default PROGRESS_BAR_CONFIGURATION;
