import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import _ from 'lodash';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import { trackAction } from '../../../actions/analytics';
import { startGame } from '../../../actions/game';
import s from './GameModalSearch.scss';
import { getSubCategoryMem } from '../../../data/model/game-category';
import { getGames } from '../../../actions/gamebrowser';

const messages = defineMessages({
  placeholderTypeToSearch: {
    id: 'placeholder.typeToSearch',
    defaultMessage: 'Search games...',
    description: 'The placeholder for the searchfield in the game screen',
  },
  textFeatured: {
    id: 'text.featured',
    defaultMessage: 'Featured',
    description: 'The category name for the category to search',
  },
  textLastPlayed: {
    id: 'text.LastPlayed',
    defaultMessage: 'Last Played',
    description: 'The category name for the category to search',
  },
  textNewGames: {
    id: 'text.newGames',
    defaultMessage: 'New',
    description: 'The category name for the category to search',
  },
});

@connect(
  (state) => ({
    auth: state.auth,
    browser: state.browser,
    licenseType: state.license.type,
    IpCountry: state.user.IpCountry,
  }),
  (dispatch) => ({
    startGame: (game, mode) => {
      dispatch(startGame(game));
      dispatch(
        trackAction('Start game', {
          variation: 'Default',
          component: 'Search',
          id: game.id,
          mode,
        }),
      );
    },
    getGames: () => dispatch(getGames()),
  }),
)
@reduxForm({
  form: 'searchForm',
})
@withStyles(s)
class GameModalSearch extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { gameCollection: null, games: [] };
    this.doSearch = _.debounce(this.doSearch, 800);
    this.gamesbox = null;
    this.loadNew = this.loadNew.bind(this);
    this.loadRecommended = this.loadRecommended.bind(this);
    this.el = {};
  }

  /*
  componentWillReceiveProps(nextProps) {
    if (nextProps.browser.lessThan.extraLarge) {
      this.setState({
        games: _.slice(this.state.gameCollection, 0, 3)
      });
    } else {
      this.setState({
        games: _.slice(this.state.gameCollection, 0, 4)
      });
    }
    TweenMax.staggerFrom(
      "." + s.searchGame,
      0.3,
      { opacity: 0, top: "-50px" },
      0.2
    );
  }
*/
  /**
   * Some handlers
   */
  doKeyup(value) {
    if (value.length > 3) {
      this.doSearch({ game: value });
    }
  }

  openGame(game) {
    this.props.startGame(
      game,
      this.props.auth.isAuthenticated ? 'Real' : 'Fun',
    );
  }

  /**
   * Get last recommended games
   * Authentication required
   */
  async loadRecommended() {
    this.el = {};
    const games = await getSubCategoryMem(
      'CASINO',
      'FEATURED',
      false,
      this.props.IpCountry,
    );
    this.setState({ games: games.instantGamesOrder.slice(0, 5) });
  }

  /**
   * Load new games
   */
  async loadNew() {
    this.el = {};
    const games = await getSubCategoryMem(
      'CASINO',
      'NEWGAMES',
      false,
      this.props.IpCountry,
    );
    this.setState({ games: games.instantGamesOrder.slice(0, 5) });
  }

  /**
   * Do a search for games
   * @param fields
   * @param params
   * @param props
   */
  doSearch(fields, params, props) {
    this.setState({ games: [] });

    this.props.getGames().then((result) => {
      const searchResultSearch = _.filter(
        result,
        _.method('name.match', new RegExp(fields.game, 'i')),
      );
      const searchResultVendor = _.filter(
        result,
        _.method('Provider.match', new RegExp(fields.game, 'i')),
      );
      const searchResult = _.concat(searchResultSearch, searchResultVendor);

      this.setState({
        games: searchResult?.slice?.(0, 5) ?? [],
      });
    });
  }

  render() {
    const { intl, auth, startGame } = this.props;

    return (
      <div className={s.root}>
        <div className={s.searchForm}>
          <Field
            name="game"
            onKeyUp={(e) => {
              this.doKeyup(e.target.value);
            }}
            id="game"
            component="input"
            placeholder={intl.formatMessage(messages.placeholderTypeToSearch)}
            type="search"
            label=""
            className={s.searchInput}
          />
          <div className={s.searchFastLinks}>
            {/*{auth.isAuthenticated && (*/}
            {/*  <a onClick={this.loadLastPlayed}>*/}
            {/*    {intl.formatMessage(messages.textLastPlayed)}*/}
            {/*  </a>*/}
            {/*)}*/}
            <div onClick={this.loadRecommended} className="cursor-pointer">
              {intl.formatMessage(messages.textFeatured)}
            </div>
            <div onClick={this.loadNew} className="cursor-pointer">
              {intl.formatMessage(messages.textNewGames)}
            </div>
          </div>
        </div>
        <div
          className={s.games}
          ref={(elm) => {
            this.gamesbox = elm;
          }}
        >
          {this.state.games.map((game, key) => (
            <div
              className={cx(s.searchGame, 'popupGames')}
              key={key}
              ref={(r) => (this.el[key] = r)}
            >
              <a
                onClick={(e) => {
                  startGame(game);
                }}
              >
                <img src={game.images.default} alt={game.name} />
              </a>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default injectIntl(GameModalSearch);
