// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BonusMeter-root-1FQAC{margin-left:5px}.BonusMeter-number-1k1-L{filter:drop-shadow(0 12px 6px rgba(0,0,0,.25))}", ""]);
// Exports
exports.locals = {
	"root": "BonusMeter-root-1FQAC",
	"number": "BonusMeter-number-1k1-L"
};
module.exports = exports;
