import React, { lazy } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';
import SsrCompatibleSuspense from '../SsrCompatibleSuspense';
import Link from '../Link';
import LogoSquare from './LogoSquare';
import SideBarMenuAuthorized from './SideBarMenu/SideBarMenuAuthorized';
import SideBarClock from './SideBarClock';
import { trackAction } from '../../actions/analytics';
import LanguageSwitcher from './LanguageSwitcher';
import plfBG from './plf-bg.png';

const ActionHolder = lazy(() =>
  import(/* webpackChunkName: 'ActionHolder' */ './ActionHolder'),
);
// const SideBarMenuAuthorized = lazy(() =>
//   import(
//     /* webpackChunkName: 'SideBarMenuAuthorized' */ './SideBarMenu/SideBarMenuAuthorized'
//   ),
// );

const messages = defineMessages({
  altLogo: {
    id: 'alt.sidebar.logo',
    defaultMessage: 'PlayFrank Online Casino - The Best in Online Slots',
    description: 'Alt text',
  },
});

@injectIntl
@connect(
  (state) => ({
    browserLessThanMedium: state.browser.lessThan.medium,
    isGameActive: state.game.isActive,
    isAuthenticated: state.auth.isAuthenticated,
    isNavigationOpen: state.navigation.isNavigationOpen,
    IpCountry: state.user.IpCountry,
    CountryCode: state.user.CountryCode,
  }),
  (dispatch) => {
    return {
      trackAction: (name, data) => dispatch(trackAction(name, data)),
    };
  },
)
class SideBar extends React.PureComponent {
  render() {
    const {
      intl,
      browserLessThanMedium,
      isAuthenticated,
      isNavigationOpen,
      isGameActive,
      openTestModal,
      IpCountry,
      CountryCode,
    } = this.props;

    return (
      <nav
        className="hidden lg:block fixed h-screen pt-5 block w-full max-w-[180px] top-0 left-0 z-30 bg-gradient-to-b from-[#0086FF] via-[#04C9C4] to-[#2A1791]"
        role="navigation"
        style={{
          background: `url(${plfBG})  no-repeat, linear-gradient(180deg, #0086FF 0.58%, #04C9C4 50%, #2A1791 110%)`,
        }}
      >
        <div className="flex flex-col justify-center items-center">
          <div className="mt-4">
            <Link to="home">
              <LogoSquare
                width="80"
                height="48"
                alt={intl.formatMessage(messages.altLogo)}
              />
            </Link>
          </div>
          {isAuthenticated && IpCountry !== 'UK' && CountryCode !== 'UK' && (
            <div className="mt-5">
              <SsrCompatibleSuspense fallback={<div />}>
                <ActionHolder />
              </SsrCompatibleSuspense>
            </div>
          )}

          <SideBarMenuAuthorized isAuthenticated={isAuthenticated} />
          {/*{!isAuthenticated ? (*/}
          {/*  <SideBarMenuPublic />*/}
          {/*) : (*/}
          {/*  <SsrCompatibleSuspense fallback={<div />}>*/}
          {/*    <SideBarMenuAuthorized />*/}
          {/*  </SsrCompatibleSuspense>*/}
          {/*)}*/}
          <div className="fixed bottom-0 w-[180px] left-0 justify-end grow-1 shrink-0">
            {isAuthenticated && CountryCode === 'DE' && (
              <div className={s.pauseButtonContainer}>
                <div className={s.pauseInfo}>
                  Mit dem Klicken von diesem Knopf, legen Sie eine 24 stündige
                  Pause ein. Dies bedeutet, dass Sie sich für 24 Stunden nicht
                  in Ihrem Konto anmelden und spielen können.
                </div>
                <a
                  onClick={window.AsgFW.callTakeABreak}
                  className={cx(
                    'attentionButton',
                    'actionButton',
                    s.pauseButton,
                    'smallButton',
                  )}
                >
                  Spielpauze
                </a>
              </div>
            )}
            <div className="font-bold px-3 pb-2 justify-end flex items-center text-xs text-white w-full ">
              {/*<LanguageSwitcher />*/}
              <SideBarClock />
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default SideBar;
