import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import close from '../../../public/img/icons/close.svg';
import s from './FullPageModal.scss';

@withStyles(s)
@connect(state => ({
  modal: state.modal,
}))
class FullPageModal extends React.Component {
  componentDidMount() {
    document.body.classList.add(s.modalOpen);
  }

  componentWillUnmount() {
    document.body.classList.remove(s.modalOpen);
  }

  render() {
    const { hasClose, modalTheme } = this.props;
    return (
      <div className={cx(s.root, modalTheme)}>
        {hasClose && (
          <div className={s.closeModal}>
            <a onClick={this.props.closePortal}>
              <svg width="25" height="25" className={s.closeButtonContainer}>
                <use
                  xlinkHref={`${close}#root`}
                  className={cx('closeButton', s.closeButton)}
                />
              </svg>
            </a>
          </div>
        )}
        {React.cloneElement(this.props.children, {
          closePortal: this.props.closePortal,
        })}
      </div>
    );
  }
}

FullPageModal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
  closePortal: PropTypes.func,
};

export default FullPageModal;
