import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import {connect} from 'react-redux';
import _ from 'lodash';
import ProgressBar from 'playfrank-components/src/progress-bar';
import PROGRESS_BAR_CONFIGURATION from './bonus-meter.constants';
import s from './BonusMeter.scss';

const mapStateToProps = state => ({
  bonus: state.bonus,
});

@connect(mapStateToProps)
@withStyles(s)
class BonusMeter extends React.Component {
  constructor(props) {
    super(props);

    this.state = { progress: 0 };
  }

  componentDidMount() {
    const {
      bonus: { activeBonuses },
    } = this.props;
    if (_.some(activeBonuses)) {
      const activeBonus = this.getActiveBonus();
      this.setProgress(
        (1 -
          activeBonus.remainingWagerRequirementAmount /
            activeBonus.initialWagerRequirementAmount) *
          100,
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      bonus: { activeBonuses },
    } = nextProps;
    if (_.some(activeBonuses)) {
      const activeBonus = this.getActiveBonus();
      this.setProgress(
        (1 -
          activeBonus.remainingWagerRequirementAmount /
            activeBonus.initialWagerRequirementAmount) *
          100,
      );
    }
  }

  setProgress(value) {
    this.setState({ progress: value });
  }

  getActiveBonus() {
    return _.find(
      this.props.bonus.activeBonuses,
      o => o.amount > 0 && o.status === 'active',
    );
  }

  render() {
    return (
      <svg width="100%" height="18">
        <ProgressBar
          {...PROGRESS_BAR_CONFIGURATION}
          end={this.state.progress}
        />
      </svg>
    );
  }
}

export default BonusMeter;
