/* eslint-disable react/prop-types */
import cx from 'classnames';
import React from 'react';
import {connect} from 'react-redux';
import {defineMessages, injectIntl} from 'react-intl';
import {trackAction} from '../../actions/analytics';

import euFlag from '../../../public/img/flags/eu.svg?external';
import gbFlag from '../../../public/img/flags/gb.svg?external';
import fiFlag from '../../../public/img/flags/fi.svg?external';
import deFlag from '../../../public/img/flags/de.svg?external';
import caFlag from '../../../public/img/flags/ca.svg?external';
import inFlag from '../../../public/img/flags/in.svg?external';
import nzFlag from '../../../public/img/flags/nz.svg?external';

const localeFlags = {
  'en-gb': gbFlag,
  'en-ca': caFlag,
  'en-in': inFlag,
  // "en-za": zaFlag,
  'en-nz': nzFlag,
  en: euFlag,
  fi: fiFlag,
  // sv: svFlag,
  de: deFlag,
  // no: noFlag,
};

const messages = defineMessages({
  default: {
    id: 'footerAltDefault',
    defaultMessage: 'online casino',
    description: 'languageswitchter alt text',
  },
  'en-gb': {
    id: 'footerAltUk',
    defaultMessage: 'online casino UK',
    description: 'languageswitchter alt text',
  },
  en: {
    id: 'footerAltEN',
    defaultMessage: 'online casino',
    description: 'languageswitchter alt text',
  },
  de: {
    id: 'footerAltDE',
    defaultMessage: 'online casino Germany',
    description: 'languageswitchter alt text',
  },
  'en-ca': {
    id: 'footerAltCA',
    defaultMessage: 'online casino Canada',
    description: 'languageswitchter alt text',
  },
  'en-nz': {
    id: 'footerAltNZ',
    defaultMessage: 'online casino New Zealand',
    description: 'languageswitchter alt text',
  },
  'en-in': {
    id: 'footerAltIN',
    defaultMessage: 'online casino India',
    description: 'languageswitchter alt text',
  },
  'en-za': {
    id: 'footerAltZA',
    defaultMessage: 'online casino South Africa',
    description: 'languageswitchter alt text',
  },
});

@injectIntl
@connect(null, dispatch => ({
  trackAction: (event, data) => dispatch(trackAction(event, data)),
}))
class LanguageSwitcher extends React.Component {
  async getData(locale) {
    const { hreflang, intl, trackAction, router } = this.props;
    console.log(hreflang, router, 'sdfsdfsfsdf');
    // try {
    //   trackAction('Switch language', {
    //     fromLocale: intl.locale,
    //     toLocale: locale,
    //     pageName: router.activeRoute.name,
    //   });

    //   window.location = url(
    //     router.activeRoute.name,
    //     _.merge(
    //       {},
    //       router.activeRoute.params,
    //       {
    //         locale,
    //         path: hreflang[locale],
    //       },
    //       _.get(router.activeRoute.intlParams, locale, {}),
    //     ),
    //   );
    // } catch (e) {
    //   window.location.href = `/${locale}`;
    // }
  }

  render() {
    const { availableLocales, intl } = this.props;

    return (
      <div className="relative flex items-center  lg:justify-end justify-center space-x-2">
        {availableLocales &&
          availableLocales.map(
            locale =>
              Object.hasOwnProperty.call(localeFlags, locale) && (
                <span key={locale}>
                  <div
                    className={cx(
                      'overflow-hidden rounded-xl inline-block align-middle border-4 border-solid border-blue hover:border-white cursor-pointer transition-all',
                      {
                        'border-white': intl.locale === locale,
                      },
                    )}
                    onClick={e => {
                      this.getData(locale);
                      e.preventDefault();
                    }}
                  >
                    <img
                      src={localeFlags[locale]}
                      width="30"
                      height="30"
                      alt={intl.formatMessage(
                        Object.hasOwnProperty.call(messages, locale)
                          ? messages[locale]
                          : messages.default,
                      )}
                      loading="lazy"
                    />
                  </div>
                </span>
              ),
          )}
      </div>
    );
  }
}

const mapState = state => ({
  availableLocales: state.runtime.availableLocales,
  intl: state.intl,
  router: state.router,
});

export default connect(mapState)(injectIntl(LanguageSwitcher));
