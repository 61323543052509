// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root{--font-family-base:\"museo-sans\",sans-serif;--font-family-variant:\"filson-pro\",sans-serif;--font-family-baloo:\"Baloo 2\",cursive;--font-size-header-01:30px;--font-size-header-02:24px;--font-size-header-03:20px;--font-size-header-04:16px;--font-size-body-intro:14px;--font-size-body:14px;--font-size-small:14px;--font-size-tiny:14px;--font-size-m-header-01:22px;--font-size-m-header-02:20px;--font-size-m-header-03:18px;--font-size-m-header-04:16px;--font-size-m-body-intro:16px;--font-size-m-body:14px;--font-size-m-small:12px;--font-size-m-tiny:10x;--max-content-width-small:720px;--max-content-width:940px;--max-content-width-large:calc(100% - 50px);--color-red:#ed6e6e;--color-darkred:#de5857;--color-darkblue:#0086ff;--color-lightblue:#6acef2;--color-blue-turquoise:#91f4fa;--color-skyblue:#f0fafe;--color-blue:#19b2e3;--color-babyblue:#d1e9f5;--color-lightblue-2:#d3f8fb;--color-yellow:#efe856;--color-green:#a0e978;--color-darkgreen:#2cbb98;--color-white:#fff;--color-ivory:#e5f5fb;--color-lightgray:#b2b2b2;--color-darkgray:#b2b2b2;--color-lightblack:#7f7f7f;--color-black:#000;--color-dark-gray:#585858;--color-lightblue-3:#d2f0fb;--color-lightblue-4:#d6eff7;--color-lightblue-5:#e2f4f9;--color-lightblue-6:#f7fcfd;--color-lightblue-7:#a7f2f8;--sidebar-width:180px}.SideBarClock-root-2yMW2{font-weight:700;font-size:.875rem;color:#fff;color:var(--color-white)}", ""]);
// Exports
exports.locals = {
	"root": "SideBarClock-root-2yMW2"
};
module.exports = exports;
