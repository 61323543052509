import cx from 'classnames';
import React from 'react';
import {withApollo} from '@apollo/client/react/hoc';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import parse from 'html-react-parser';
import getCopyright from './get-copyright.graphql';
import {transform} from '../../core/aspire';

let data = null;
let promise = null;

@withApollo
@connect(
  state => ({
    license: state.license,
    browser: state.browser,
    IpCountry: state.user.IpCountry,
    alpha3: state.intl.alpha3,
    ssrData: state.cache[`license_${state.intl.alpha3}`],
  }),
  dispatch => ({
    setCache: (key, cacheData) =>
      dispatch({ type: 'SET_CACHE', payload: { key, cacheData } }),
  }),
)
@injectIntl
class License extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showMore: false, content: '' };
    this.toggleShowMore = this.toggleShowMore.bind(this);
  }

  toggleShowMore() {
    const { showMore } = this.state;
    this.setState({ showMore: !showMore });
  }

  linkRenderer(props) {
    return (
      <a href={props.href} target="_blank" rel="noopener noreferrer">
        {props.children}
      </a>
    );
  }

  // componentDidMount() {
  //   const { client, ipCountry, intl, alpha3 } = this.props;
  //
  //   client
  //     .query({
  //       query: getCopyright,
  //       variables: {
  //         lng: alpha3,
  //         branddomain: 'playfrank.com',
  //         companycountry: 'MT',
  //         companyname: 'playfrank',
  //         irishLicense: false,
  //         country: ipCountry.toLowerCase(),
  //       },
  //     })
  //     .then(result => {
  //       this.setState({ content: result.data.getCopyright.data.copyrightText });
  //     });
  // }

  render() {
    const { intl, license, browser, client, IpCountry, alpha3 } = this.props;
    const { showMore, content } = this.state;

    // if (browser.lessThan.small && !showMore) {
    //   content = _.truncate(content, {
    //     length: 200,
    //   });
    // }
    if (data || this.props.ssrData) {
      if (!this.props.ssrData)
        this.props.setCache(`license_${alpha3}`, { ...data });
      const license = (
        <div className="relative w-full py-10">
          <div
            className={cx(
              'text-xs text-white prose max-w-none prose-a:text-white',
            )}
          >
            {parse(
              data ? data.copyrightText : this.props.ssrData.copyrightText,
              { replace: transform },
            )}
          </div>
        </div>
      );

      data = null;
      promise = null;
      return license;
    }

    if (!promise) {
      promise = new Promise(resolve => {
        client
          .query({
            query: getCopyright,
            variables: {
              lng: alpha3,
              // branddomain: 'playfrank.com',
              // companycountry: 'MT',
              // companyname: 'playfrank',
              irishLicense: false,
              country: IpCountry.toLowerCase(),
            },
          })
          .then(result => {
            resolve();

            data = result.data.getCopyright.data;
          });
      });
    }
    throw promise;
  }
}

export default License;
