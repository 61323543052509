import withStyles from 'isomorphic-style-loader/withStyles';
import _ from 'lodash';
import React, { lazy } from 'react';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';
import SsrCompatibleSuspense from '../SsrCompatibleSuspense';
import s from './Game.scss';
import { updateAlternate } from '../../core/DOMUtils';
import { localeUrl } from '../../core/url';
import { locales } from '../../config';
import closeic from './close.svg';
import fullscreenic from './fullscreen.svg';

const XpMissionMeter = lazy(() =>
  import(/* webpackChunkName: 'XpMissionMeter' */ './XpMissionMeter'),
);

const messages = defineMessages({
  metaDesc: {
    id: 'title.desc',
    defaultMessage:
      'for Free or Real Money at PlayFrank Online Casino. Sign up today and get €300 Welcome Bonus + 200 Free Spins.',
    description: 'Meta for games',
  },
});
@connect((state) => ({
  currentXpMissions: state.xperience.currentXpMissions,
  isGameActive: state.game.isActive,
  isMobile: state.device.isMobile,
}))
@injectIntl
class Game extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      hasXpMission: false,
      currentXpMission: false,
      isGameActive: true,
    };
    this.updateAlternate = this.updateAlternate.bind(this);
    this.hasActiveXpMission = this.hasActiveXpMission.bind(this);
    this.timer = null;
    this.isActiveMission = false;
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   // Store prevUserId in state so we can compare when props change.
  //   // Clear out any previously-loaded user data (so we don't render stale stuff).
  //   if (nextProps.isGameActive !== prevState.isGameActive) {
  //     return {
  //       isGameActive: nextProps.isGameActive,
  //     };
  //   }
  //
  //   // No state update necessary
  //   return null;
  // }

  componentDidMount() {
    const { game, currentXpMissions, mode } = this.props;

    // updateMeta(game?.name);
    // const target = document.getElementById('ng-game-container');

    // // Create an observer instance
    // let redirect = false;
    // const observer = new MutationObserver(function( mutations ) {
    //   mutations.forEach(( mutation ) => {
    //     if(game.VendorTableId && !redirect && document.getElementsByClassName('mwc-instant-game-web-iframe') && document.getElementsByClassName('mwc-instant-game-web-iframe')[0].src !== '') {
    //       observer.disconnect();
    //       redirect = true;
    //       const url = new URL(document.getElementsByClassName('mwc-instant-game-web-iframe')[0].src)
    //       var urlParams = new URLSearchParams(url.search);
    //       var decodedData = window.atob(urlParams.get('params'));
    //       urlParams.set('params', window.btoa(`${decodedData}
    //       table_id=${game.VendorTableId}`));
    //       return document.getElementsByClassName('mwc-instant-game-web-iframe')[0].src = `${url.protocol}//${url.host}${url.pathname}?${urlParams.toString()}`;
    //     }
    //   });
    // });
    //
    // // Configuration of the observer:
    // const config = {
    //   attributes: true,
    //     characterData: true,
    //     childList: true,
    //     subtree: true,
    //     attributeOldValue: true,
    //     characterDataOldValue: true,
    // };
    //
    // // Pass in the target node, as well as the observer options
    // observer.observe(target, config);

    // this.updateAlternate(game.slug);
    // updateMeta('robots', 'Index, Follow');
  }

  hasActiveXpMission(id, currentXpMissions) {
    let hasActiveXpMission = false;

    try {
      _.forEach(currentXpMissions, (value, key) => {
        _.forEach(value.missionProgress, function (progress, key) {
          if (
            id &&
            JSON.parse(progress.rawGames).indexOf(id) > -1 &&
            progress.status == 'active' &&
            progress.locked == 0
          ) {
            hasActiveXpMission = value;
          }
        });
      });
    } catch (e) {}
    return hasActiveXpMission;
  }

  componentWillUnmount() {
    const { intl } = this.props;
    if (this.timer) clearInterval(this.timer);
  }

  updateAlternate(slug) {
    if (slug) {
      const alternate = {};
      locales.map((lang) => {
        alternate[lang] = `https://www.playfrank.com${localeUrl('game', lang, {
          slug,
        })}`;
      });
      updateAlternate(alternate);
    }
  }

  fullscreen() {
    const element = document.getElementById('gameModal');

    // Supports most browsers and their versions.
    const requestMethod =
      element.requestFullScreen ||
      element.webkitRequestFullScreen ||
      element.mozRequestFullScreen ||
      element.msRequestFullScreen;

    if (requestMethod) {
      if (this.state.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
        this.setState({ fullscreen: false });
      } else {
        this.setState({ fullscreen: true });
        requestMethod.call(element);
      }
    } else if (typeof window.ActiveXObject !== 'undefined') {
      // Older IE.
      const wscript = new ActiveXObject('WScript.Shell');
      if (wscript !== null) {
        wscript.SendKeys('{F11}');
      }
    }
  }

  render() {
    const { game, currentXpMissions, isMobile, intl } = this.props;
    const { currentXpMission, isGameActive } = this.state;
    const hasXpMission = this.hasActiveXpMission(game.id, currentXpMissions);
    if (isMobile) {
      return (
        <>
          {/*{hasXpMission && (*/}
          {/*  <SsrCompatibleSuspense fallback={<div />}>*/}
          {/*    <XpMissionMeter game={game} currentXpMission={hasXpMission} />*/}
          {/*  </SsrCompatibleSuspense>*/}
          {/*)}*/}
          <div id="ng-game-container" className={'s.gameFrameMobile'} />
        </>
      );
    }
    // if (game?.name) {
    //   updateTitle(`Play ${game?.name} - Playfrank Online Casino`);
    //
    //   updateMeta(
    //     'description',
    //     `Play ${game?.name} by ${
    //       game?.externalGameProvider
    //     } ${intl.formatMessage(messages.metaDesc)}`,
    //   );
    //   updateCustomMeta(
    //     'og:description',
    //     `Play ${game?.name} by ${
    //       game?.externalGameProvider
    //     } ${intl.formatMessage(messages.metaDesc)}`,
    //   );
    // }
    return (
      <>
        {/* <Helmet>
          <meta
            name="title"
            content="Play {Wild Beach Party} {Slot} - Playfrank Online Casino"
          />
        </Helmet> */}

        <div
          className="flex flex-col px-2 lg:px-10 justify-center items-center w-full h-screen"
          style={{
            backgroundImage: `url('${_.get(game, 'backgroundImageUrl')}')`,
          }}
        >
          <div className="bg-white top-0 absolute inset-0 w-full h-full" />
          <div
            className="flex flex-col lg:flex-row-reverse items-center px-4 justify-between w-full bg-[#222222] py-2 rounded-t-md overflow-hidden relative"
            style={{
              maxWidth: `calc((100vh - ${
                false ? '20.879999999999999rem' : '7.5rem'
              })*${
                _.get(game, 'width', 600 * game.aspectRatio) /
                _.get(game, 'height', 600)
              })`,
            }}
          >
            <div className="flex items-center">
              <h1 className="text-sm font-medium text-white m-0 mr-2">
                {game?.name}{' '}
                {game?.externalGameSubProvider &&
                  ` - ${game?.externalGameSubProvider}`}{' '}
                {game?.externalGameProvider &&
                  `| ${game?.externalGameProvider}`}
              </h1>
              <div className="btn btn--primary">Play for real</div>
            </div>
            <div className="flex items-center  space-x-4">
              <div
                className="flex cursor-pointer items-center text-white font-medium text-sm cursor-pointer"
                onClick={window.AsgFW.closeGame}
              >
                <img src={closeic} alt="" className="mr-2" />
                Close
              </div>
              <div
                onClick={this.fullscreen.bind(this)}
                className="flex items-center text-white font-medium text-sm cursor-pointer"
              >
                <img src={fullscreenic} alt="" className="mr-2" />
                Full screen
              </div>
            </div>
          </div>
          <div
            className="relative block w-full bg-[#222] rounded-b-md overflow-hidden"
            style={{
              maxWidth: `calc((100vh - ${
                false ? '20.879999999999999rem' : '7.5rem'
              })*${
                _.get(game, 'width', 600 * game.aspectRatio) /
                _.get(game, 'height', 600)
              })`,
            }}
          >
            {hasXpMission && (
              <SsrCompatibleSuspense fallback={<div />}>
                <XpMissionMeter game={game} currentXpMission={hasXpMission} />
              </SsrCompatibleSuspense>
            )}
            <div
              id="ng-game-container"
              className={'absolute left-0 w-full h-full border-none'}
            />
            <div
              style={{
                paddingTop: `${
                  (_.get(game, 'height', 600) /
                    _.get(game, 'width', 600 * game.aspectRatio)) *
                  100
                }%`,
              }}
            />
          </div>
        </div>
      </>
    );
  }
}

export default Game;
