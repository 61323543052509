import React from 'react';
import {defineMessages, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
// icons
import {trackAction} from '../../../actions/analytics';
import {startDeposit} from '../../../actions/deposit';
import plus from '../../../../public/img/icons/plus-circle.svg';

const messages = defineMessages({
  deposit: {
    id: 'deposit',
    defaultMessage: 'Deposit',
    description: 'General deposit word',
  },
  withdraws: {
    id: 'withdraws',
    defaultMessage: 'Withdraws',
    description: 'General word',
  },
  bonus: {
    id: 'bonus',
    defaultMessage: 'Bonus',
    description: 'General word',
  },
  rewards: {
    id: 'rewards',
    defaultMessage: 'Rewards',
    description: 'General word',
  },
  blog: {
    id: 'blog',
    defaultMessage: 'Blog',
    description: 'General word',
  },
  games: {
    id: 'games',
    defaultMessage: 'Games',
    description: 'General word',
  },
  depositNow: {
    id: 'depositNow',
    defaultMessage: 'Deposit now',
    description: 'General word',
  },
  dailyChallengesTitle: {
    id: 'dailyChallengesTitle',
    defaultMessage: 'Daily Challenges',
    description: 'Name of the xp missions',
  },
});

const DepositButton = ({ wallet, startDeposit, intl }) => {
  const handleClick = () => {
    startDeposit();
    trackAction('Start Deposit', {
      variation: 'Default',
      component: 'SideBarMenuAuthorized',
    });
  };

  return (
    <a className="btn btn--primary btn--lg w-full" onClick={handleClick}>
      {wallet.total > 0
        ? intl.formatNumber(wallet.total, {
            style: 'currency',
            currencyDisplay: 'symbol',
            currency: wallet.baseCurrency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : intl.formatMessage(messages.deposit)}
      {wallet.total > 0 && (
        <svg className="w-6 h-6 ml-2 absolute right-4">
          <use xlinkHref={`${plus}#root`} />
        </svg>
      )}
    </a>
  );
};

const mapStateToProps = state => ({
  wallet: state.wallet,
});

const mapDispatchToProps = dispatch => ({
  startDeposit: () => {
    dispatch(startDeposit());
    dispatch(
      trackAction('Start Deposit', {
        variation: 'Default',
        component: 'SideBarMenuAuthorized',
      }),
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(DepositButton));
