import React, { useEffect } from 'react';
import useStyles from 'isomorphic-style-loader/withStyles';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { openModal } from '../../actions/modal';
import Game from '../Game';
import SideBarClock from '../SideBar/SideBarClock';
import GameModalSearch from './GameModalSearch';
// import s from './GameModal.scss';
import GameModalContainer from '../Modal/GameModalContainer';
import { useSubscription } from '@apollo/client';
import dailyChallengeCompleteSubscription from './complete.graphql';

const GameModal = ({ mode }) => {
  // useStyles(s);
  const dispatch = useDispatch();
  const game = useSelector((state) => state.game);
  const isMobile = useSelector((state) => state.device.isMobile);
  const EncryptedUserID = useSelector((state) => state.user.EncryptedUserID);
  const openDailyChallengeCompleteModal = (data) =>
    dispatch(openModal('XPMISSION_GAME_COMPLETE_MODAL', data));

  const { data, loading } = useSubscription(
    dailyChallengeCompleteSubscription,
    { variables: { uid: EncryptedUserID } },
  );
  console.log('completeDailyChallenge', data);
  useEffect(() => {
    if (data?.completeDailyChallenge?.status === 'complete') {
      openDailyChallengeCompleteModal(data?.completeDailyChallenge);
    }
  }, [data]);

  useEffect(() => {
    if (game.isActive) {
      document.body.classList.add('overflow-hidden');
    }

    if (!game.isActive) {
      document.body.classList.remove('overflow-hidden');
    }
  }, [game]);

  return isMobile ? (
    <GameModalContainer
      closePortal={window.AsgFW.closeGame}
      isActive={game.isActive}
      isMobile={isMobile}
    >
      <Game game={game} mode={mode} />
    </GameModalContainer>
  ) : (
    <GameModalContainer
      closePortal={window.AsgFW.closeGame}
      isActive={game.isActive}
    >
      <Game game={game} mode={mode} />
      {/* <div className={cx(s.root)}>
          <div className={s.clock}>
            <SideBarClock />
          </div>
          <Game game={game} mode={mode} />

          <div className={s.search}>
            <GameModalSearch game={game} />
          </div>
        </div> */}
    </GameModalContainer>
  );
};

export default GameModal;
