import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import _ from 'lodash';
import React from 'react';
import {connect} from 'react-redux';
import {boundMethod} from 'autobind-decorator';

import close from '../../../public/img/icons/close.svg';
import Navigator from '../Form/Navigator';
import s from './PseudoModal.scss';

const Themes = {
  smallHeader: s.smallHeader,
  contentModal: s.contentModal,
};

@withStyles(s)
@connect(state => ({
  browser: state.browser,
  modal: state.modal,
}))
class PseudoModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
      backAction: null,
      closeAction: null,
      navigatorVisible: true,
    };
  }

  componentDidMount() {
    document.body.classList.add(s.modalOpen);
  }

  componentWillUnmount() {
    const { modal } = this.props;

    if (_.size(modal) <= 1) {
      document.body.classList.remove(s.modalOpen);
    }
  }

  @boundMethod
  onBeforeClose() {
    const { closePortal } = this.props;

    if (this.state.closeAction) {
      this.state.closeAction().then(() => closePortal());
    } else {
      closePortal();
    }
  }

  @boundMethod
  setBackAction(backAction) {
    this.setState({ backAction });
  }

  @boundMethod
  setCloseAction(closeAction = null) {
    this.setState({ closeAction });
  }

  @boundMethod
  setTitle(title = '') {
    this.setState({ title });
  }

  @boundMethod
  navigatorVisible(navigatorVisible = true) {
    this.setState({ navigatorVisible });
  }

  render() {
    const { hasClose, browser, modalTheme, newUI, modalHeader } = this.props;

    return (
      <div className={cx(s.root, newUI ? s.newUI : '')}>
        <div className={s.backDrop} />
        <div className={s.container} ref={this.props.modalRef}>
          {browser.lessThan.large && this.state.navigatorVisible && (
            <div className={s.modalHeader}>
              <Navigator
                title={this.state.title}
                closeAction={hasClose ? this.onBeforeClose : false}
                backAction={this.state.backAction}
              />
            </div>
          )}
          {hasClose && browser.greaterThan.medium && (
            <a
              onClick={this.onBeforeClose}
              className={s.closeModal}
              id="closeModal"
            >
              <svg>
                <use xlinkHref={`${close}#root`} />
              </svg>
            </a>
          )}
          {modalHeader && (
            <div
              className={cx(s.headerContainer, _.get(Themes, modalTheme, ''))}
              id="headerContainer"
            >
              <img src={modalHeader} className={s.headerImg} alt="" />
            </div>
          )}
          <div className={s.contentContainer}>
            {React.cloneElement(this.props.children, {
              setTitle: this.setTitle,
              setBackAction: this.setBackAction,
              navigatorVisible: this.navigatorVisible,
              setCloseAction: this.setCloseAction,
              closePortal: this.props.closePortal,
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default PseudoModal;
