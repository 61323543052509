import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import s from './LogoSquare.scss';
import logoSquare from './logoSquare.svg';

@withStyles(s)
class LogoSquare extends React.PureComponent {
  static propTypes = {
    height: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { height, width, alt } = this.props;
    return <img width={width} height={height} src={logoSquare} alt={alt} />;
  }
}

export default LogoSquare;
