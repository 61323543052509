import React from 'react';
import {defineMessages, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import Link from '../Link';
import BottomBarMenuItemNavigation from './BottomBarMenuItemNavigation';
import {openModal} from '../../actions/modal';
import {trackAction, trackClick} from '../../actions/analytics';
import s from './BottomBarMenuPublic.scss';
import {startAuthentication} from '../../actions/auth';
import SideBarClock from '../SideBar/SideBarClock';

const messages = defineMessages({
  slugLiveLobby: {
    id: 'slug.liveLobby',
    defaultMessage: 'live-lobby',
    description: 'The SEO description for the home page',
  },
  labelLogin: {
    id: 'label.login',
    description: 'Button text for login',
    defaultMessage: 'Log in',
  },
  textRegister: {
    id: 'text.register',
    defaultMessage: 'register',
    description: 'register text',
  },
});

@connect(
  state => ({
    isGameBrowserActive: state.navigation.isGameBrowserActive,
    browser: state.browser,
    activeRouteName: state.router.activeRoute.name,
    lobbyType: state.router.activeRoute.params.lobbyType,
    IpCountry: state.user.IpCountry,
    isNavigationOpen: state.navigation.isNavigationOpen,
  }),
  dispatch => ({
    openGameCategoryModal: () => dispatch(openModal('GAME_CATEGORY_MODAL')),
    openGameSearchModal: () => dispatch(openModal('GAME_SEARCH_MODAL')),
    openGameFilterModal: () => dispatch(openModal('GAME_FILTER_MODAL')),
    trackAction: (event, data) => dispatch(trackAction(event, data)),
    startAuthentication: () => {
      dispatch(startAuthentication());
      dispatch(
        trackClick('Login', {
          variation: 'Default',
          component: 'TopBarMenuAuthorized',
        }),
      );
    },
  }),
)
@injectIntl
@withStyles(s)
class BottomBarMenuPublic extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { counter: null };
  }

  // componentDidMount() {
  //   this.fetchData();
  // }

  // componentDidUpdate(prevProps) {
  //   if (prevProps.activeRouteName === 'promotion') {
  //     this.fetchData();
  //   }
  // }

  // @autobind
  // fetchData() {
  //   const { intl } = this.props;
  //   let counter = 0;
  //   find('promotions', { active: 1, activeAspire: 1 }, 300, 0, intl.locale, 0, {
  //     weight: -1,
  //   }).then(result => {
  //     const lastVisit = localStorage.getItem('LAST_VISIT_BLOG');

  //     if (!lastVisit) {
  //       this.setState({ counter: result.entries.length });
  //     } else {
  //       console.log('calc');
  //       result.entries.map(promo => {
  //         if (
  //           isAfter(
  //             Date.parse(parseISO(promo.startdate)),
  //             Date.parse(lastVisit),
  //           )
  //         ) {
  //           console.log('counter');
  //           counter++;
  //         }
  //       });
  //       this.setState({ counter });
  //     }
  //   });
  // }

  render() {
    const {
      intl,
      isGameBrowserActive,
      activeRouteName,
      lobbyType,
      openGameSearchModal,
      openGameCategoryModal,
      IpCountry,
      startAuthentication,
      isNavigationOpen,
    } = this.props;
    console.log(isNavigationOpen, 'isNavigationOpenisNavigationOpen');
    return (
      <>
        <BottomBarMenuItemNavigation label="More" className="w-1/5" />
        {!isNavigationOpen && (
          <div className="flex space-x-2 items-center">
            <Link
              className="btn btn--primary"
              to="register"
              data-id="register"
              onClick={this.trackRegister}
            >
              {intl.formatMessage(messages.textRegister)}
            </Link>
            <Link
              to="auth"
              className="btn btn--white-outline"
              onClick={startAuthentication}
              data-id="login"
            >
              <span>{intl.formatMessage(messages.labelLogin)}</span>
            </Link>
          </div>
        )}
        {isNavigationOpen && (
          <div className="flex items-center justify-end">
            <SideBarClock />
            {/* <div>
              <a
                onClick={e => {
                  e.preventDefault();
                  if (window?.AsgFW) {
                    window.AsgFW.showChat();
                  }
                }}
                type="button"
              >
                <img src={chat} alt="chat" />
              </a>
            </div> */}
          </div>
        )}
      </>
    );
  }
}

export default BottomBarMenuPublic;
