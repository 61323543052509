import cx from 'classnames';
import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import s from './BottomBarMenuItemNavigation.scss';
import ToggleNavigation from '../Navigation/ToggleNavigation';

const BottomBarMenuItemNavigation = props => {
  useStyles(s);

  const { label, className } = props;
  return (
    <>
      <ToggleNavigation className={cx(s.root, className)} classNameIcon="">
        <span className="text-xs w-full px-1 mt-0.5 truncate leading-none text-center">
          {label}
        </span>
      </ToggleNavigation>
    </>
  );
};

export default BottomBarMenuItemNavigation;
