// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BottomBarMenuItem-root-1Dww_{height:50px;width:100px;max-width:20%;z-index:2;will-change:transform;text-decoration:none!important;color:var(--color-white);box-sizing:border-box;position:relative;transition:all .5s;display:flex;flex-direction:column;justify-content:space-between;align-items:center;padding:5px}.BottomBarMenuItem-root-1Dww_>svg{height:27px;min-width:27px}.BottomBarMenuItem-root-1Dww_ span{font-size:10px;width:100%;line-height:10px;text-align:center;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.BottomBarMenuItem-badge-1mLZd{background:var(--color-yellow);color:var(--color-red);filter:drop-shadow(0 1px 3px rgba(0,0,0,.25));border-radius:50%;position:absolute;margin-left:15px;margin-top:-10px;font-size:12px;height:23px!important;width:23px!important;text-align:center;font-weight:700;display:flex;align-items:center;justify-content:center}.BottomBarMenuItem-active-3tNqD{background:hsla(0,0%,100%,.15)}", ""]);
// Exports
exports.locals = {
	"root": "BottomBarMenuItem-root-1Dww_",
	"badge": "BottomBarMenuItem-badge-1mLZd",
	"active": "BottomBarMenuItem-active-3tNqD"
};
module.exports = exports;
